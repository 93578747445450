import { initUtils } from "@tma.js/sdk";
import { chunk } from "lodash";
import { FC, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { v1 } from "uuid";

import "swiper/swiper-bundle.css";
import { AppItem, AppItemSkeleton } from "@/entities/app";
import { ImageSwapper } from "@/widgets/apps/ui/AppsList/ImageSwapper";
import { App, IAppsResult } from "@/shared/api/apps";

import { useHaptic } from "@/shared/lib/hooks/useHaptic";

import s from "./AppsListDescktop.module.sass";

interface AppsListProps {
    apps: IAppsResult[];
    banners: App[];
    isLoading: boolean;
}

export const AppsListDesktop: FC<AppsListProps> = ({ apps, banners, isLoading }) => {
    const { t } = useTranslation();
    const utils = useMemo(() => initUtils(), []);
    const navigate = useNavigate();

    const groupedItems = useMemo(() => {
        return apps.reduce<Record<string, IAppsResult[]>>((acc, item) => {
            item.categories?.forEach((category) => {
                if (!acc[category]) acc[category] = [];
                acc[category].push(item);
            });
            return acc;
        }, {});
    }, [apps]);

    const triggerHaptic = useHaptic();

    const handleNavigate = useCallback(
        (href: string, type: string) => {
            triggerHaptic();
            if (type === "TG") {
                utils.openTelegramLink(href);
            } else if (type === "INTERNAL") {
                navigate(href);
            } else {
                utils.openLink(href);
            }
        },
        [navigate, utils]
    );

    const renderSection = useCallback(
        (category: string, items: IAppsResult[], isAll = false) => {
            const chunkedItems = chunk(items, 3);
            const slidesPerView = items.length > 3 ? 1.14 : 1;

            return (
                <div key={category}>
                    <div className={s.titleContainer}>
                        <div className={s.title}>
                            {category === "all-apps" ? t("common.all-apps") : category}
                        </div>
                        <Link
                            to={`/apps/${category}`}
                            className={s.button}
                            onClick={() => triggerHaptic()}
                        >
                            {t("apps.view-all")}
                        </Link>
                    </div>
                    <Swiper
                        spaceBetween={12}
                        slidesPerView={slidesPerView}
                        allowTouchMove={true}
                        mousewheel={true}
                        className={s.appList}
                    >
                        {chunkedItems.map((chunk, index) => (
                            <SwiperSlide key={index}>
                                <div className={s.wrapper}>
                                    {isLoading
                                        ? Array(3)
                                              .fill(null)
                                              .map((_, index) => <AppItemSkeleton key={index} />)
                                        : chunk.map((el) => (
                                              <AppItem
                                                  isDesktop={true}
                                                  {...el}
                                                  key={v1()}
                                                  isLastInSlider={
                                                      (!isAll && index === 2) || chunk.length === 1
                                                  }
                                              />
                                          ))}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            );
        },
        [isLoading, t]
    );

    return (
        <>
            <Swiper
                allowTouchMove={true}
                mousewheel={{ forceToAxis: true }}
                autoplay={{ delay: 4000 }}
                spaceBetween={10}
                slidesPerView={1.05}
                freeMode={true}
                observer={true}
                observeParents={true}
                touchReleaseOnEdges={true}
                modules={[Autoplay]}
                className={s.appList}
            >
                {banners.map((banner) => (
                    <SwiperSlide key={v1()}>
                        <div onClick={() => handleNavigate(banner.link.href, banner.link.type)}>
                            <ImageSwapper imageUrl={banner.background}>
                                {banner?.preview && (
                                    <div className={s.bannerLine}>
                                        <img className={s.bannerPreview} src={banner.preview} />
                                        <div className={s.nameContainer}>
                                            {banner?.title && (
                                                <div>
                                                    <strong
                                                        style={
                                                            banner.titleColor
                                                                ? { color: banner.titleColor }
                                                                : {}
                                                        }
                                                    >
                                                        {banner.title}
                                                    </strong>
                                                </div>
                                            )}
                                            {banner?.description && (
                                                <div>
                                                    <span
                                                        style={
                                                            banner.descriptionColor
                                                                ? { color: banner.descriptionColor }
                                                                : {}
                                                        }
                                                    >
                                                        {banner.description}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </ImageSwapper>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            {groupedItems.DeLab && renderSection("DeLab", groupedItems.DeLab)}
            {Object.keys(groupedItems)
                .filter((category) => category !== "DeLab")
                .map((category) => renderSection(category, groupedItems[category]))}

            {apps && renderSection("all-apps", apps, true)}
        </>
    );
};
