import { FC, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { TokenListItem, TokenListItemSkeleton } from "@/entities/token/ui";
import { Button, SearchInput } from "@/shared/components";
import { SvgSelector } from "@/shared/lib/assets/svg-selector";
import { useHaptic } from "@/shared/lib/hooks/useHaptic";
import { CHAINS, TokenBalance, TotalBalance } from "@/shared/lib/types";
import s from "./TokensList.module.sass";

interface TokensListSelectable {
    search?: boolean;
    accountBalance: TotalBalance | null;
    onTokenSelect: (token: TokenBalance) => void;
    isSelectMode: true;
    includedImportsIcon?: boolean;
    isLoading?: boolean;
    chainFilter?: CHAINS[];
    tokenList?: TokenBalance[];
}

interface TokensList {
    search?: boolean;
    accountBalance: TotalBalance | null | undefined;
    onTokenSelect?: undefined;
    isSelectMode?: false;
    includedImportsIcon?: boolean;
    isLoading?: boolean;
    chainFilter?: CHAINS[];
    tokenList?: TokenBalance[];
}

export const TokensList: FC<TokensList | TokensListSelectable> = ({
    search,
    accountBalance,
    onTokenSelect,
    isSelectMode,
    includedImportsIcon,
    isLoading = false,
    chainFilter,
    tokenList,
}) => {
    const [searchValue, setSearchValue] = useState<string>("");

    const navigate = useNavigate();

    const triggerHaptic = useHaptic();

    const sortedTokens: TokenBalance[] = useMemo(() => {
        if (accountBalance) {
            const allTokens = tokenList ?? [
                // PROD-TON-ONLY // ); //     [] as TokenBalance[] //     (pv, cv) => [...pv, cv.nativeToken, ...(cv.tokens ?? [])], // Object.values(accountBalance.chains).reduce( // DEV
                accountBalance.chains.TON.nativeToken,
                // accountBalance.chains.ETH.nativeToken,
                // accountBalance.chains.BNB.nativeToken,
                ...(accountBalance.chains.TON.tokens ?? []),
                // ...(accountBalance.chains.ETH.tokens ?? []),
                // ...(accountBalance.chains.TRON.tokens ?? []),
                // ...(accountBalance.chains.BNB.tokens ?? []),
            ];
            return allTokens;
        } else return [];
    }, [accountBalance, tokenList]);

    const filteredTokens: TokenBalance[] = useMemo(() => {
        const filtered = sortedTokens.filter(
            (token) =>
                token.tokenSymbol.toLowerCase().includes(searchValue.toLowerCase().trim()) &&
                (chainFilter ? chainFilter.includes(token.platform) : true)
        );
        return filtered;
    }, [searchValue, sortedTokens, chainFilter]);

    // filter tokens that have address EQAJ8uWd7EBqsmpSWaRdf_I-8R8-XHwh3gsNKhy-UrdrPcUo and place it in first place
    // TODO: remove hardcoded address
    const sortedTokensWithHMSTR = useMemo(() => {
        const tokensWithHMSTR = filteredTokens.filter(
            (token) => token.tokenContract === "EQAJ8uWd7EBqsmpSWaRdf_I-8R8-XHwh3gsNKhy-UrdrPcUo"
        );
        const tokensWithoutHMSTR = filteredTokens.filter(
            (token) => token.tokenContract !== "EQAJ8uWd7EBqsmpSWaRdf_I-8R8-XHwh3gsNKhy-UrdrPcUo"
        );
        return [...tokensWithHMSTR, ...tokensWithoutHMSTR];
    }, [filteredTokens]);

    return (
        <div className={s.tokensList}>
            {search && (
                <div
                    className={s.tokensListTop}
                    style={{ display: includedImportsIcon ? "flex" : "" }}
                >
                    <SearchInput setValue={setSearchValue} value={searchValue} />
                    {includedImportsIcon && (
                        <Button
                            type="primary"
                            className={s.importBtn}
                            onClick={() => {
                                navigate("/import/token");
                                triggerHaptic();
                            }}
                            isDisabled
                        >
                            <SvgSelector id="import" />
                        </Button>
                    )}
                </div>
            )}
            <div className={s.list}>
                {isLoading ? (
                    <TokenListItemSkeleton count={5} />
                ) : (
                    sortedTokensWithHMSTR.map((token) => (
                        <TokenListItem
                            key={
                                token.tokenID +
                                token.platform +
                                token.tokenSymbol +
                                token.tokenContract
                            }
                            name={token.tokenSymbol}
                            balance={token.balance}
                            balanceUSD={token.balanceUSD}
                            tokenPrice={token.price}
                            change24={token.change24h}
                            chain={token.isNativeToken ? undefined : token.platform}
                            icon={token.tokenIcon}
                            isClaimed={token.isClaimed}
                            claimInfo={token.claimInfo}
                            onClick={
                                isSelectMode
                                    ? () => {
                                          onTokenSelect?.(token);
                                          triggerHaptic();
                                      }
                                    : undefined
                            }
                        />
                    ))
                )}
            </div>
        </div>
    );
};
