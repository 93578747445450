export const emojiList = [
    "🦐",
    "🦑",
    "🐠",
    "🦈",
    "🐳",
    "🐹",
    "🐷",
    "🐸",
    "😀",
    "😂",
    "😅",
    "🤡",
    "😈",
    "😇",
    "😱",
    "🤯",
    "🥶",
    "🤓",
    "😵",
    "😡",
    "🤩",
    "💅",
    "👻",
    "💰",
    "🍋",
    "🍕",
    "🍉",
    "🥇",
    "🥈",
    "🥉",
    "⏳",
    "💩",
    "🏆",
    "🚀",
    "🍄",
    "💸",
    "🔒",
    "✏️",
    "🤌",
    "📌",
    "🔥",
    "🎁",
    "💡",
    "⚠️",
    "❤️",
    "💙",
    "💜",
    "🧡",
    "🧨",
    "🔔",
    "⛔",
    "♻️",
    "🎒",
    "👑",
    "💦",
    "✨",
];
