import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCreateConfirm, useFirstCreate } from "@/features/account/add";
import { useGetUseBiometryQuery, usePINConfirmation, usePINCreation } from "@/features/PIN";
import { useImportAccountMutation } from "@/entities/multichainAccount";
import { Checkbox, Loader, Title } from "@/shared/components";
import { BaseLayout } from "@/shared/layouts";
import { useSetupBackButton, useSetupMainButton } from "@/shared/lib";
import { sendNotification } from "@/shared/lib/helpers/sendNotification";
import styles from "./ImportMnemonicPage.module.scss";
import { MnemonicInput } from "./MnemonicInput/MnemonicInput";

export const ImportMnemonicPage = () => {
    const { t } = useTranslation();
    const [useLegacy, setUseLegacy] = useState<boolean>(false);
    const [mnemonic, setMnemonic] = useState<string[]>([]);
    const [importAccount, { error, isLoading: importAccountLoading }] = useImportAccountMutation();
    const navigate = useNavigate();
    const { confirm } = usePINConfirmation();
    const { createPIN } = usePINCreation();
    const { createConfirm, isLoading: createLoading } = useCreateConfirm(confirm);
    const { data: isUseBiometry } = useGetUseBiometryQuery();
    const { firstCreate, isLoading: firstCreateLoading } = useFirstCreate(createPIN, isUseBiometry);
    const isLoading = createLoading || firstCreateLoading;

    const onImport = useCallback(async () => {
        try {
            const data = await importAccount({ mnemonic, useLegacy }).unwrap();
            if (data.isNew) {
                firstCreate(data);
            } else {
                createConfirm(data);
            }
        } catch (err) {
            console.error("Import failed:", err);
        }
    }, [importAccount, createConfirm, mnemonic, firstCreate, useLegacy]);

    const onChange = (value: string[]) => {
        setMnemonic(value);
    };

    const isEnabled = mnemonic.length === 12 || mnemonic.length === 24;

    const onClick = useCallback(() => {
        if (isEnabled && !importAccountLoading && !isLoading) {
            onImport();
        }
    }, [isEnabled, importAccountLoading, onImport, isLoading]);

    const onCheck = useCallback(() => {
        setUseLegacy((prev) => {
            if (prev) {
                return false;
            } else {
                if (window.confirm(t("registration.legacy-confirm"))) {
                    return true;
                }
                return prev;
            }
        });
    }, [t]);

    useEffect(() => {
        if (error && error.message) {
            sendNotification(error.message, "error");
        }
    }, [error]);

    useSetupBackButton();
    useSetupMainButton({
        onClick,
        params: {
            text: t("registration.import-wallet2"),
            isVisible: true,
            isEnabled,
            isLoaderVisible: importAccountLoading || isLoading,
        },
    });

    return isLoading ? (
        <Loader />
    ) : (
        <BaseLayout>
            <Title className={styles.title}>{t("registration.import-wallet2")}</Title>
            <div className={styles.subtitle}>{t("registration.seed")}</div>
            <MnemonicInput onChange={onChange} />
            {mnemonic.length === 12 && (
                <div className={styles.legacyCheckbox}>
                    <Checkbox isConfirmed={useLegacy} setIsConfirmed={onCheck} />
                    {t("registration.use-legacy")}
                </div>
            )}
        </BaseLayout>
    );
};
