import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { registerSlice } from "@/shared/lib";

import { IAppsResult, appsApi } from "./AppsService";

interface AppsState {
    apps: IAppsResult[];
    loading: boolean;
    error: string | null;
}

const initialState: AppsState = {
    apps: [],
    loading: false,
    error: null,
};

const appsSlice = createSlice({
    name: "apps",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(appsApi.endpoints.getApps.matchPending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(
                appsApi.endpoints.getApps.matchFulfilled,
                (state, action: PayloadAction<IAppsResult[]>) => {
                    state.apps = action.payload;
                    state.loading = false;
                }
            )
            .addMatcher(appsApi.endpoints.getApps.matchRejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to fetch apps";
            });
    },
});

registerSlice([appsSlice]);

export const appsReducer = appsSlice.reducer;
