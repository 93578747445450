import { skipToken } from "@reduxjs/toolkit/query";
import { Address } from "@ton/core";
import { ceil } from "lodash";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SelectNetwork } from "@/widgets/import";
import { SwapInfo } from "@/widgets/swap/ui/SwapInfo/SwapInfo";
import { SwapInner } from "@/widgets/swap/ui/SwapInner/SwapInner";
import { TokensList } from "@/widgets/token";
import { FailedTransaction, SuccessTransaction } from "@/widgets/transaction";
import { usePINConfirmation } from "@/features/PIN";
import { coffeeSwap } from "@/features/swap";
import { useGetAssetListQuery, useGetRouteQuery } from "@/features/swap/model/coffeeSwapService";
import {
    MultichainAccount,
    multichainAccountStore,
    useFetchTotalBalanceQuery,
} from "@/entities/multichainAccount";
import { PrivateLayout } from "@/shared/layouts";
import {
    cryptographyController,
    useAppSelector,
    useSetupBackButton,
    useSetupMainButton,
} from "@/shared/lib";
import { SvgSelector } from "@/shared/lib/assets/svg-selector";
import { getSelectBlockhainConfig } from "@/shared/lib/consts/import-list";
import { getExplorerLink } from "@/shared/lib/helpers/getExplorerLink";
import { sendNotification } from "@/shared/lib/helpers/sendNotification";
import { CHAINS, TokenBalance } from "@/shared/lib/types";
import { SwapSteps } from "../lib/types";
// import s from "./Swap.module.sass";

export const Swap: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { confirm } = usePINConfirmation();
    const searchParams = new URLSearchParams(location.search);
    const preselectedNetwork: string | null = searchParams.get("network");
    const preselectedInput: TokenBalance | null = location.state?.input ?? null;

    // убрал SwapSteps.selectNetwork
    const [step, setStep] = useState<SwapSteps>(SwapSteps.swap);
    // вместо null поставил CHAINS.TON
    const [network, setNetwork] = useState<CHAINS | null>(
        preselectedNetwork ? (preselectedNetwork as CHAINS) : CHAINS.TON,
    );
    const [tokenFrom, setTokenFrom] = useState<TokenBalance | null>(null);
    const [tokenTo, setTokenTo] = useState<TokenBalance | null>(null);
    const [fromAmount, setFromAmount] = useState<number>(0);
    const [toAmount, setToAmount] = useState<number>(0);
    const [showTokenList, setShowTokenList] = useState<"token1" | "token2" | null>(null);
    const [processing, setProcessing] = useState<boolean>(false);
    const [transactionError, setTransactionError] = useState<string>("");
    const [reversed, setReversed] = useState(false);
    // const [swapInfo, setSwapInfo] = useState<Route | null>(null);

    const account = useAppSelector(multichainAccountStore.selectors.selectAccount);
    const tonVersion = useAppSelector(multichainAccountStore.selectors.selectTonVersion);
    const multichainAccount = useMemo(
        () => (account ? new MultichainAccount(account, tonVersion) : undefined),
        [account, tonVersion],
    );
    const { data: accountBalance = null, isFetching: accountBalanceFetching } =
        useFetchTotalBalanceQuery();

    // Доступные ассеты
    const { data: assetList = null } = useGetAssetListQuery();
    // Получаем роут для свапа
    const {
        data: swapInfo,
        isFetching: isFetchingRoute,
        isError: isRouteError,
    } = useGetRouteQuery(
        tokenFrom && tokenTo
            ? {
                  inputToken: !tokenFrom.tokenContract
                      ? "native"
                      : Address.parse(tokenFrom.tokenContract!),
                  outputToken: !tokenTo.tokenContract
                      ? "native"
                      : Address.parse(tokenTo.tokenContract!),
                  amount: reversed ? toAmount : fromAmount,
                  inputAmount: !reversed,
              }
            : skipToken,
        { pollingInterval: 10000 },
    );
    const isEnoughBalance = (tokenFrom?.balance ?? 0) >= fromAmount;
    const isTonSelected = tokenFrom?.isNativeToken && tokenFrom.platform === CHAINS.TON;
    const isSufficientTonForComm =
        (accountBalance?.chains.TON.nativeToken.balance ?? 0) >=
        (swapInfo?.recommended_gas ?? 0) + (isTonSelected ? fromAmount : 0);

    // CHECK FROM SEARCH PARAMS
    useEffect(() => {
        if (preselectedNetwork && multichainAccount) {
            const chain = preselectedNetwork ? (preselectedNetwork as CHAINS) : null;
            if (!chain) return;
            setStep(SwapSteps.swap);
            setNetwork(preselectedNetwork as CHAINS);
        }
    }, [preselectedNetwork, multichainAccount]);

    // Обновляем токен при изменении swapInfo
    useEffect(() => {
        if (swapInfo) {
            setFromAmount(ceil(swapInfo.input_amount, 4));
            setToAmount(ceil(swapInfo.output_amount, 4));
        }
    }, [swapInfo]);

    // Сообщение об ошибке
    useEffect(() => {
        if (swapInfo === null || isRouteError) sendNotification(t("swap-modal.not-found"), "error");
    }, [swapInfo, isRouteError]);

    // Объединяем балансы и список ассетов
    const parsedTokens = useMemo(() => {
        const userTonAssets = accountBalance?.chains.TON.tokens ?? [];
        if (!assetList || !accountBalance) return [];
        const res = assetList?.map<TokenBalance>(
            (asset) =>
                userTonAssets.find(
                    (userAsset) =>
                        userAsset.tokenContract === Address.parse(asset.address).toString(),
                ) ?? {
                    tokenContract: Address.parse(asset.address).toString(),
                    tokenID: asset.name,
                    tokenSymbol: asset.symbol,
                    tokenName: asset.name,
                    tokenIcon: asset.image,
                    platform: CHAINS.TON,
                    isNativeToken: false,
                    decimals: asset.decimals,
                    balance: 0,
                    balanceUSD: 0,
                    price: asset.price_usd,
                    change24h: asset.price_change_24h,
                },
        );
        return [accountBalance.chains.TON.nativeToken, ...res];
    }, [assetList, accountBalance]);

    // Сетаем дефолтные токены по загрузке балика
    // Если инпут тон, ставим в пару с DFC
    // Если инпут жетон, ставим в пару с TON
    useEffect(() => {
        if (accountBalance && network) {
            const allTokens = [
                accountBalance.chains[network].nativeToken,
                ...(accountBalance.chains[network].tokens || []),
            ];
            if (preselectedInput) {
                if (preselectedInput.isNativeToken && preselectedInput.platform === CHAINS.TON) {
                    setTokenFrom(preselectedInput);
                    setTokenTo(
                        allTokens.find((token) => token.tokenID === "DeFinder Capital") ||
                            allTokens[1] ||
                            null,
                    );
                } else {
                    setTokenFrom(preselectedInput);
                    setTokenTo(allTokens[0] || null);
                }
            } else {
                setTokenFrom(accountBalance.chains[network].nativeToken);
                setTokenTo(
                    allTokens.find((token) => token.tokenID === "DeFinder Capital") ||
                        allTokens[1] ||
                        null,
                );
            }
        }
    }, [accountBalance, network, preselectedInput]);

    const disableMainBtn = useMemo(() => {
        return !!(
            step !== SwapSteps.swap ||
            !network ||
            !tokenFrom ||
            !tokenTo ||
            showTokenList ||
            !swapInfo ||
            swapInfo?.paths.length < 1 ||
            !isEnoughBalance ||
            !isSufficientTonForComm
        );
    }, [
        step,
        network,
        tokenFrom,
        tokenTo,
        showTokenList,
        swapInfo,
        isEnoughBalance,
        isSufficientTonForComm,
    ]);

    const onSwap = useCallback(async () => {
        if (!disableMainBtn && multichainAccount) {
            const account = multichainAccount._account;
            try {
                // Confirm
                const title = t("send.confirm-transaction");
                const pin = await confirm({
                    title,
                });
                const mnemonics = cryptographyController.HashToKey(
                    pin,
                    account.masterIV,
                    account.masterHash,
                );
                if (!mnemonics) {
                    throw new Error("Invalid PIN");
                }
                setProcessing(true);

                // Build
                const boc = await coffeeSwap.buildBOC({
                    addressUser: Address.parse(multichainAccount.getAddressInNetwork(CHAINS.TON)),
                    paths: swapInfo?.paths,
                });
                if (!boc.data?.transactions) throw new Error("Invalid BOC");
                // Send
                const result = await multichainAccount?._tonWallet.sendRawTrx(
                    mnemonics,
                    undefined,
                    boc.data.transactions.map((tx) => ({
                        amount: tx.value,
                        address: tx.address,
                        payload: tx.cell,
                    })),
                );
                if (result) setStep(SwapSteps.success);
                else setStep(SwapSteps.failed);
            } catch (error) {
                console.error(error);
                toast((error as Error).message);
                setTransactionError((error as Error).message);
            } finally {
                setProcessing(false);
            }
        }
    }, [swapInfo?.paths, disableMainBtn, multichainAccount]);

    const onBack = useCallback(() => {
        if (showTokenList) {
            setShowTokenList(null);
        } else if (step === SwapSteps.success || step === SwapSteps.failed) {
            navigate("/home");
        } else {
            navigate(-1);
        }
    }, [showTokenList, navigate]);

    useSetupMainButton({
        onClick: onSwap,
        params: {
            text: !isSufficientTonForComm
                ? t("swap-modal.insufficient-ton")
                : isEnoughBalance
                  ? t("swap-modal.confirm-swap")
                  : t("swap-modal.insufficient"),
            textColor: "#FFFFFF",
            bgColor: "#424B56",
            isLoaderVisible: processing,
            isEnabled: !disableMainBtn,
            isVisible: !!(step === SwapSteps.swap && network && !showTokenList),
        },
    });
    useSetupBackButton({
        onBack,
    });

    const onTokenSelect = useCallback(
        (token: TokenBalance) => {
            if (showTokenList === "token1") {
                setTokenFrom(token);
            } else {
                setTokenTo(token);
            }
            setShowTokenList(null);
        },
        [showTokenList],
    );

    const onSelectNetwork = () => {
        setStep(SwapSteps.swap);
    };

    const changeAmountFrom = (value: number) => {
        setReversed(false);
        setFromAmount(value);
    };
    const changeAmountTo = (value: number) => {
        setReversed(true);
        setToAmount(value);
    };

    const explorerLink = getExplorerLink({
        userAddress: multichainAccount?.getAddressInNetwork(CHAINS.TON),
        chain: CHAINS.TON,
    });

    return (
        <PrivateLayout>
            {/* {step === SwapSteps.selectNetwork && (
                <SelectNetwork
                    setNetwork={setNetwork}
                    network={network}
                    config={getSelectBlockhainConfig([CHAINS.TON])}
                    onClick={onSelectNetwork}
                    mainTitle
                />
            )} */}
            {step === SwapSteps.swap &&
                (showTokenList && network ? (
                    <TokensList
                        search
                        isSelectMode
                        accountBalance={accountBalance}
                        onTokenSelect={onTokenSelect}
                        isLoading={accountBalanceFetching}
                        chainFilter={[network]}
                        tokenList={parsedTokens}
                    />
                ) : (
                    <SwapInner
                        poweredBy="swap.coffee"
                        poweredIcon={<SvgSelector id="coffee" />}
                        tokenFrom={tokenFrom}
                        setTokenFrom={setTokenFrom}
                        tokenTo={tokenTo}
                        setTokenTo={setTokenTo}
                        setShowTokenList={setShowTokenList}
                        fromAmount={fromAmount}
                        setFromAmount={changeAmountFrom}
                        toAmount={toAmount}
                        setToAmount={changeAmountTo}
                        swapInfoComponent={
                            <SwapInfo
                                priceImpact={(swapInfo?.price_impact ?? 0) * 100}
                                gas={swapInfo?.recommended_gas}
                                gasSymbol="TON"
                                gasUSD={
                                    (swapInfo?.recommended_gas ?? 0) *
                                    (accountBalance?.chains.TON.nativeToken.price ?? 0)
                                }
                                loading={isFetchingRoute}
                            />
                        }
                    />
                ))}
            {step === SwapSteps.success && <SuccessTransaction explorerLink={explorerLink} />}
            {step === SwapSteps.failed && <FailedTransaction />}
        </PrivateLayout>
    );
};
