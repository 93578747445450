export enum CHAINS {
    ETH = "ETH",
    BNB = "BNB",
    TON = "TON",
    TRON = "TRON",
}

export type EVM_CHAINS = CHAINS.ETH | CHAINS.BNB;
export const EVM_CHAINS_ARRAY = [CHAINS.ETH, CHAINS.BNB];
export const isEVMChain = (chain: CHAINS): chain is EVM_CHAINS => EVM_CHAINS_ARRAY.includes(chain);

export enum TON_ADDRESS_INTERFACES {
    V5 = "V5",
    V4 = "V4",
    V3R1 = "V3R1",
    V3R2 = "V3R2",
}

export interface ITONWallet {
    publicKey: string;
    address: {
        V5: string;
        V4: string;
        V3R1: string;
        V3R2: string;
    };
}

export interface IStandartWalletData {
    publicKey: string;
    address: string;
}

export interface IMultiwallet {
    TON: ITONWallet;
    ETH: IStandartWalletData;
    TRON: IStandartWalletData;
}

export interface IMultichainAccount {
    masterIV: string;
    masterHash: string;
    multiwallet: IMultiwallet;
    id: string;
    name?: string;
    emojiId?: string;
}

export interface BaseToken {
    tokenContract?: string;
    tokenID: string;
    tokenSymbol: string;
    tokenName: string;
    tokenIcon?: string;
    platform: CHAINS;
    decimals?: number;
    isNativeToken: boolean;
    price?: number;
    custom_payload_api_uri?: string;
}

export interface TokenClaimInfo {
    owner: string;
    jetton_wallet: string;
    custom_payload: string;
    state_init: string;
    compressed_info: {
        amount: string;
        start_from: string;
        expired_at: string;
    };
}

export function airDropStarted(info: TokenClaimInfo): boolean {
    const now = new Date().getTime() / 1000;
    const from = parseInt(info.compressed_info.start_from);
    const to = parseInt(info.compressed_info.expired_at);

    return now >= from && now <= to;
}

export interface TokenBalance extends BaseToken {
    balance: number;
    balanceUSD: number;
    price: number;
    change24h?: number;
    claimInfo?: TokenClaimInfo;
    isClaimed?: boolean;
}

export interface TotalBalanceChainInfo {
    nativeToken: TokenBalance;
    tokens?: TokenBalance[];
}

type ChainBalances = Record<CHAINS, TotalBalanceChainInfo>;

export interface TotalBalance {
    totalUSDBalance: number;
    chains: ChainBalances;
}
