import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AppsList } from "@/widgets/apps";
import { AppsListDesktop } from "@/widgets/apps/ui/AppsList/AppsListDesktop";
import { AppsListSingle } from "@/widgets/apps/ui/AppsList/AppsListSingle";
import { useGetAppsQuery, useGetBannersQuery } from "@/shared/api/apps";
import { PrivateLayout } from "@/shared/layouts";
import { useSetupBackButton } from "@/shared/lib";
import s from "./Apps.module.scss";

export const Apps: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id?: string }>();
    const { data, isLoading, error } = useGetAppsQuery("");
    const bannersQuery = useGetBannersQuery("");
    const banners = bannersQuery.data?.apps || [];

    const platform = window?.Telegram?.WebApp?.platform || null;
    useSetupBackButton();

    if (error) {
        return (
            <PrivateLayout>
                <div className={s.error}>{t("common.apps-error-loading")}</div>
            </PrivateLayout>
        );
    }

    if (id && id !== "all-apps") {
        return (
            <PrivateLayout>
                <AppsListSingle
                    data={data?.filter(({ categories }) => categories?.includes(id)) || []}
                    isLoading={isLoading}
                />
            </PrivateLayout>
        );
    }

    if (id && id === "all-apps") {
        return (
            <PrivateLayout>
                <AppsListSingle data={data || []} isLoading={isLoading} />
            </PrivateLayout>
        );
    }

    return (
        <PrivateLayout>
            {["ios", "android"].includes(platform!) ? (
                <AppsList
                    apps={data || []}
                    banners={banners}
                    isLoading={isLoading || bannersQuery.isLoading}
                />
            ) : (
                <AppsListDesktop
                    apps={data || []}
                    banners={banners}
                    isLoading={isLoading || bannersQuery.isLoading}
                />
            )}
        </PrivateLayout>
    );
};
