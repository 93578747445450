import { Buffer } from "buffer";
import { Maybe } from "@tma.js/sdk";
import {
    Address,
    Cell,
    TonClient,
    beginCell,
    WalletContractV4,
    WalletContractV3R1,
    WalletContractV5R1,
    WalletContractV3R2,
    comment,
    internal,
    toNano,
    TonClient4,
    JettonMaster,
    fromNano,
    loadStateInit,
    storeStateInit,
    SendMode,
    contractAddress,
    address,
    external,
    StateInit,
    storeMessage,
} from "@ton/ton";
import { ethers } from "ethers";
import { Coins } from "ton3";
import { cryptographyController } from "@/shared/lib";
import { workchain } from "@/shared/lib/consts/ton/index";
import { TokenClaimInfo, TON_ADDRESS_INTERFACES } from "@/shared/lib/types/multichainAccount";
import { tonAPIClient, tonClient4, tonClient4Delab } from "../../tonapi";
import { withFallback } from "../lib/helpers/withFallback";
import {
    TonTxDTO,
    TransToSignJetton,
    TransToSignTon,
    TransferJettonDTO,
    TransferTonDTO,
} from "../lib/types";
import { TransferOptions } from "./../lib/types/";
import { TokenWallet } from "./jettonApi";

window.Buffer = Buffer;
const standbyV2Client = new TonClient({
    endpoint: "https://toncenter.com/api/v2/jsonRPC",
    apiKey: "94d8d3dde3b37be15e77baf5c3800bcafc5dcb8ec9f50c6b14bb8ed9fd79cb62",
});

// todo: убрать все интерфейсы, связанные с тонконнектом, в папку моделей тонконнекта
interface SignRawMessage {
    address: string;
    amount: string; // (decimal string): number of nanocoins to send.
    payload?: string; // (string base64, optional): raw one-cell BoC encoded in Base64.
    stateInit?: string; // (string base64, optional): raw once-cell BoC encoded in Base64.
}

export class TonWalletService {
    // private readonly client: TonClient4;
    public readonly publicKey: string;
    public readonly tonAddress: string;
    public readonly version: TON_ADDRESS_INTERFACES;

    constructor(publicKey: string, tonAddress: string, version: TON_ADDRESS_INTERFACES) {
        this.publicKey = publicKey;
        this.tonAddress = tonAddress;
        this.version = version;
    }

    public static createWalletByVersion(
        version: TON_ADDRESS_INTERFACES = TON_ADDRESS_INTERFACES.V4,
        publicKey: string
    ): WalletContractV5R1 | WalletContractV4 {
        const publicKeyBuffer = Buffer.from(publicKey, "hex");

        switch (version) {
            case TON_ADDRESS_INTERFACES.V5:
                return WalletContractV5R1.create({
                    publicKey: publicKeyBuffer,
                }) as WalletContractV5R1;
            case TON_ADDRESS_INTERFACES.V4:
                return WalletContractV4.create({
                    workchain,
                    publicKey: publicKeyBuffer,
                }) as WalletContractV4;
            case TON_ADDRESS_INTERFACES.V3R2:
                return WalletContractV3R2.create({
                    workchain,
                    publicKey: publicKeyBuffer,
                }) as WalletContractV4;
            case TON_ADDRESS_INTERFACES.V3R1:
                return WalletContractV3R1.create({
                    workchain,
                    publicKey: publicKeyBuffer,
                }) as WalletContractV4;
            default:
                throw new Error(`Unsupported wallet version: ${version}`);
        }
    }

    // метод для быстрого получения баланса TON (для жетонов брать балансы из tonapi)
    public async balanceTon(version: TON_ADDRESS_INTERFACES = this.version): Promise<string> {
        const balanceTonMethod = async (
            client: TonClient4,
            version: TON_ADDRESS_INTERFACES = this.version
        ) => {
            const wallet = TonWalletService.createWalletByVersion(version, this.publicKey);
            const contract = client.open(wallet);
            const bal = await contract.getBalance();
            return fromNano(bal);
        };

        return withFallback(balanceTonMethod, version);
    }

    private createTransfer(contract: any, args: any): any {
        return contract.createTransfer(args);
    }

    public buildExternalMessage(body: Maybe<Cell>) {
        const walletContract = TonWalletService.createWalletByVersion(this.version, this.publicKey);
        const walletAddress = walletContract.address;

        const ext = external({
            to: walletAddress,
            init: { code: walletContract.init.code, data: walletContract.init.data },
            body: body,
        });

        return beginCell().store(storeMessage(ext)).endCell().toBoc();
    }

    public async claimTokens(claimInfo: TokenClaimInfo, secretKey: Buffer) {
        const walletContract = TonWalletService.createWalletByVersion(this.version, this.publicKey);
        const stateInit = loadStateInit(Cell.fromBase64(claimInfo.state_init).beginParse());

        const transferBody = beginCell()
            .storeUint(0xf8a7ea5, 32)
            .storeUint(0, 64) // op, queryId
            .storeCoins(toNano("0.1"))
            .storeAddress(walletContract.address)
            .storeAddress(walletContract.address)
            .storeMaybeRef(Cell.fromBase64(claimInfo.custom_payload))
            .storeCoins(1n)
            .storeBit(0);

        const messages = [
            internal({
                value: toNano("0.1"),
                to: Address.parse(claimInfo.jetton_wallet),
                bounce: false,
                body: transferBody.endCell(),
                init: {
                    code: stateInit.code,
                    data: stateInit.data,
                },
            }),
        ];

        if (claimInfo.jetton_wallet != contractAddress(0, stateInit).toRawString()) {
            throw new Error("Invalid jetton wallet address");
        }

        const contract = tonClient4.open(walletContract);
        const seqno = await contract.getSeqno();

        const transfer = this.createTransfer(contract, {
            seqno,
            secretKey: secretKey,
            sendMode: SendMode.PAY_GAS_SEPARATELY,
            messages: messages,
        });

        const external = this.buildExternalMessage(transfer);
        const message = external.toString("base64");

        const response = await fetch("https://tonapi.io/v2/blockchain/message", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                boc: message,
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to send message: ${response.statusText}`);
        }
    }

    public async sendTon(
        txData: TonTxDTO,
        secretKey: string,
        version: TON_ADDRESS_INTERFACES = this.version
    ): Promise<boolean> {
        const sendTonMethod = async (
            client: TonClient4,
            txData: TonTxDTO,
            secretKey: string,
            version: TON_ADDRESS_INTERFACES
        ) => {
            try {
                const wallet = TonWalletService.createWalletByVersion(version, this.publicKey);
                const contract = client.open(wallet);
                const seqno: number = await contract.getSeqno();
                if (!Address.parse(txData.to).toString()) {
                    console.error("Invalid receiver address");
                    return false;
                }
                let comm;
                if (txData.comment) comm = comment(txData.comment);
                const body = txData.data ? Cell.fromBase64(txData.data) : undefined;

                const msgs = [
                    internal({
                        value: BigInt(txData.amount),
                        to: Address.parse(txData.to),
                        bounce: false,
                        body: body ?? comm,
                    }),
                ];
                const transfer = this.createTransfer(contract, {
                    seqno,
                    secretKey: Buffer.from(secretKey, "hex"),
                    sendMode: 1 + 2,
                    messages: msgs,
                });
                await contract.send(transfer);
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        };

        return await withFallback(sendTonMethod, txData, secretKey, version);
    }

    // метод для упаковки state init, приходящего с тонконнекта
    private static parseStateInit(stateInit: string) {
        const { code, data } = loadStateInit(Cell.fromBase64(stateInit).asSlice());
        return { code, data };
    }

    // метод для подписи готовых месседжей, приходящих из тонконнекта
    public async sendRawTrx(
        mnemonic: string,
        version: TON_ADDRESS_INTERFACES = this.version,
        transArray: SignRawMessage[]
    ): Promise<boolean> {
        const sendRawTrxMethod = async (
            client: TonClient4,
            mnemonic: string,
            version: TON_ADDRESS_INTERFACES,
            transArray: SignRawMessage[]
        ) => {
            try {
                const wallet = await cryptographyController.tonWalletFromUnknownMnemonic(
                    mnemonic,
                    this.publicKey
                );
                const walletContract = TonWalletService.createWalletByVersion(
                    version,
                    this.publicKey
                );

                const contract = client.open(walletContract);
                const seqno: number = await contract.getSeqno();
                const balance: bigint = await contract.getBalance();

                for (const trans of transArray) {
                    if (Number(balance) < Number(trans.amount)) {
                        console.error(
                            `ERROR: balance < value. Value is ${trans.amount}, when the balance is ${balance}`
                        );
                        return false;
                    }

                    if (!Address.parse(trans.address).toString()) {
                        console.error("ERROR: !Address");
                        return false;
                    }
                }

                const msgs = transArray.map((trans) => {
                    return internal({
                        value: BigInt(trans.amount),
                        to: Address.parse(trans.address),
                        bounce: false,
                        body: trans.payload ? Cell.fromBase64(trans.payload) : undefined,
                        init: trans.stateInit
                            ? TonWalletService.parseStateInit(trans.stateInit)
                            : undefined,
                    });
                });
                const transfer = this.createTransfer(contract, {
                    seqno,
                    secretKey: Buffer.from(wallet.secretKey, "hex"),
                    sendMode: 1,
                    messages: msgs,
                });

                await contract.send(transfer);
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        };

        return await withFallback(sendRawTrxMethod, mnemonic, version, transArray);
    }

    // метод, который подрписывает и трансферит транзакцию в TON
    public async signMsg(
        txData: TransToSignTon,
        mnemonic: string,
        version: TON_ADDRESS_INTERFACES = this.version
    ): Promise<boolean> {
        try {
            const wallet = await cryptographyController.tonWalletFromUnknownMnemonic(
                mnemonic,
                this.publicKey
            );
            // console.log("wallet", wallet);
            const sentTx = await this.sendTon(
                {
                    to: txData.to.toString({ bounceable: false }),
                    amount: txData.amount,
                    data: txData.data,
                    comment: txData.comment,
                },
                wallet.secretKey,
                version
            );
            console.log("sentTx", sentTx);

            return sentTx;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    public async transferTon({
        to,
        amount,
        mnemonics,
        memo = "",
    }: TransferTonDTO): Promise<boolean> {
        try {
            const receiver = Address.parse(to);
            if (!receiver.toString({ bounceable: false })) throw new Error("Invalid receiver");

            return await this.signMsg(
                {
                    to: receiver,
                    amount: toNano(amount).toString(),
                    comment: memo,
                },
                mnemonics
            );
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    private async resolveJettonAddressFor(
        jettonMasterAddress: Address,
        userContractAddress: Address
    ): Promise<Address | undefined> {
        const resolveJettonAddressForMethod = async (
            client: TonClient4,
            jettonMasterAddress: Address,
            userContractAddress: Address
        ) => {
            try {
                const jettonMaster = client.open(JettonMaster.create(jettonMasterAddress));
                jettonMaster.getJettonData();
                const address = await jettonMaster.getWalletAddress(userContractAddress);
                return address;
            } catch (err) {
                // standbyV2Client
                const waletAddress = await standbyV2Client.runMethod(
                    jettonMasterAddress,
                    "get_wallet_address",
                    [
                        {
                            type: "slice",
                            cell: beginCell().storeAddress(userContractAddress).endCell(),
                        },
                    ]
                );
                try {
                    const cell = waletAddress.stack.readCell();
                    const address = cell.beginParse().loadAddress();
                    return address;
                } catch {
                    console.error(err);
                    return undefined;
                }
            }
        };

        return await withFallback(
            resolveJettonAddressForMethod,
            jettonMasterAddress,
            userContractAddress
        );
    }

    // метод, который подписывает и трансферит транзакцию любого жеттона (любой токен на TON, кроме самого TON)
    public async transferJetton({
        to,
        amount,
        mnemonics,
        tokenAddress,
        memo = "",
    }: TransferJettonDTO): Promise<boolean> {
        try {
            const tokenParsedAddress = Address.parse(tokenAddress);
            const receiver = Address.parse(to);
            if (!receiver.toString({ bounceable: false })) throw new Error("Invalid receiver");
            const jettonData = await tonAPIClient.getJettonDataById({
                jettonAddress: tokenAddress,
            });

            const dataJetton = TonWalletService.sendJettonToBoc(
                {
                    to: receiver,
                    amount: ethers.parseUnits(amount.toString(), jettonData.decimals).toString(),
                    comment: memo,
                },
                this.tonAddress
            );

            const jettonWallet = await this.resolveJettonAddressFor(
                tokenParsedAddress,
                Address.parse(this.tonAddress)
            );

            if (!jettonWallet) {
                return false;
            }

            const txToTon: TransToSignTon = {
                to: jettonWallet,
                amount: new Coins("0.05").toNano(), // was default 0.2
                data: dataJetton,
            };

            const result = await this.signMsg(txToTon, mnemonics);
            // console.log('result', result)
            return result;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    // метод, который подписывает и трансферит транзакцию NFT на TON
    public async signMsgNFT(
        addressNFT: string,
        addressTo: string,
        mnemonic: string,
        message?: string
    ): Promise<boolean> {
        const dataJ = TonWalletService.sendNFTToBoc(addressTo, this.tonAddress, message);

        const trToTon: TransToSignTon = {
            to: Address.parse(addressNFT),
            amount: new Coins("0.2").toNano(),
            data: dataJ,
        };

        const hash = await this.signMsg(trToTon, mnemonic);

        return hash;
    }

    public static sendJettonToBoc(tr: TransToSignJetton, addressUser: string): string {
        const transJetton: TransferOptions = {
            queryId: 1,
            tokenAmount: BigInt(tr.amount),
            to: Address.parse(tr.to.toString()), // to address
            responseAddress: Address.parse(addressUser.toString()),
            comment: tr.comment,
        };

        const boc = TokenWallet.buildTransferMessage(transJetton);

        const base64 = boc.toBoc().toString("base64");

        return base64;
    }

    public static sendNFTToBoc(addressTo: string, addressUser: string, message?: string): string {
        const boc = TokenWallet.buildNFTTransferMessage(addressTo, addressUser, message);

        const base64 = boc.toBoc().toString("base64");
        return base64;
    }

    public async getStateInit({ version }: { version: string }) {
        let wallet = null;

        wallet = WalletContractV4.create({
            workchain,
            publicKey: Buffer.from(this.publicKey, "hex"),
        });

        if (version === "V5") {
            wallet = WalletContractV5R1.create({
                publicKey: Buffer.from(this.publicKey, "hex"),
            });
        }
        if (version === "V3R1") {
            wallet = WalletContractV3R1.create({
                workchain,
                publicKey: Buffer.from(this.publicKey, "hex"),
            });
        }
        if (version === "V3R2") {
            wallet = WalletContractV3R2.create({
                workchain,
                publicKey: Buffer.from(this.publicKey, "hex"),
            });
        }

        const initialCode = wallet.init.code;
        const initialData = wallet.init.data;
        const stateInitCell = beginCell()
            .store(storeStateInit({ code: initialCode, data: initialData }))
            .endCell();

        return {
            stateInit: stateInitCell.toBoc({ idx: false }).toString("base64"),
            wallet,
        };
    }
}
