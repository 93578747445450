import { Address } from "@ton/core";
import { CHAINS, TokenBalance } from "../../../lib/types";
import { GetRatesDTO, JettonDataCoffee } from "../types";
import { JettonMetadataResponse } from "../types/getJettonDataByIdDTO";

export const jettonDataToTokenBalance = (
    token: JettonMetadataResponse,
    rates: JettonDataCoffee | null
): TokenBalance => {
    const contract = Address.parse(token.metadata.address).toString({ bounceable: true });
    const metadata = token.metadata;

    return {
        tokenContract: contract, // bounceable: true для API коингеко
        tokenID: metadata?.name,
        tokenSymbol: metadata?.symbol,
        tokenIcon: metadata?.image,
        tokenName: metadata?.name,
        balanceUSD: 0,
        balance: 0,
        change24h: rates
            ? parseFloat(rates.price_change_24h.toString().replace("−", "-").replace("%", ""))
            : 0,
        price: rates?.price_usd || 0,
        platform: CHAINS.TON,
        isNativeToken: false,
        decimals: Number(metadata?.decimals),
        custom_payload_api_uri: metadata?.custom_payload_api_uri,
    };
};
