import { skipToken } from "@reduxjs/toolkit/query";
import * as Sentry from "@sentry/react";
import { t } from "i18next";
import queryString from "query-string";
import { FC, useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TransactionsHistoryListDetail } from "@/widgets/transaction";
import { usePINConfirmation } from "@/features/PIN";
import {
    MultichainAccount,
    multichainAccountStore,
    useDeleteImportedTokenMutation,
    useFetchTotalBalanceQuery,
    useGetImportedTokensQuery,
} from "@/entities/multichainAccount";
import { useGetTokenPriceHistoryQuery } from "@/entities/token/model/tokenService";
import { TokenDetailInfo } from "@/entities/token/ui";
import { AlertMessage, BigButton } from "@/shared/components";
import { Chart } from "@/shared/components/Chart";
import { SkeletonRect } from "@/shared/components/Skeletons";
import { BaseLayout } from "@/shared/layouts";
import { cryptographyController, useAppSelector, useSetupBackButton } from "@/shared/lib";
import { sendNotification } from "@/shared/lib/helpers/sendNotification";
import { useHaptic } from "@/shared/lib/hooks/useHaptic";
import { airDropStarted, CHAINS, TokenBalance } from "@/shared/lib/types";
import { TokenDetailQueryObj } from "@/shared/lib/types/token";
import s from "./TokenDetail.module.scss";

type ClaimResult = {
    isError: boolean;
    errorMessage?: string;
};

export const TokenDetail: FC = () => {
    useSetupBackButton();
    const location = useLocation();
    const navigate = useNavigate();
    const query: TokenDetailQueryObj = Object(
        queryString.parse(location.search, { parseBooleans: true })
    );

    const { data: accountBalance, isLoading: accountBalanceLoading } = useFetchTotalBalanceQuery();
    const { data: savedTokens, isFetching: savedTokensFetching } = useGetImportedTokensQuery();
    const [deleteImportedToken] = useDeleteImportedTokenMutation();

    const [isProcessing, setProcessing] = useState<boolean>(false);
    const [isClaimed, setIsClaimed] = useState<boolean>(false);

    const { confirm } = usePINConfirmation();

    const account = useAppSelector(multichainAccountStore.selectors.selectAccount);
    const tonVersion = useAppSelector(multichainAccountStore.selectors.selectTonVersion);
    const multichainAccount = useMemo(
        () => (account ? new MultichainAccount(account, tonVersion) : undefined),
        [account, tonVersion]
    );

    const triggerHaptic = useHaptic();

    // Получение баланса выбранного токена из общего списка балансов
    const tokenBalance: TokenBalance | undefined = useMemo(() => {
        // console.log(params.id, accountBalance);
        if (accountBalance && query) {
            const { platform, tokenContract, isNativeToken } = query;
            if (isNativeToken)
                // если токен нативный
                return accountBalance.chains[platform as CHAINS].nativeToken;
            else if (accountBalance.chains[platform as CHAINS].tokens)
                // если токен не нативный, ищем в чейне совпадение по ID
                return accountBalance.chains[platform as CHAINS].tokens?.find(
                    (token) => token.tokenContract === tokenContract
                );
            else return undefined;
        } else return undefined;
    }, [query, accountBalance]);

    const { data: priceHistory = [], isFetching: priceHistoryFetching } =
        useGetTokenPriceHistoryQuery(tokenBalance ?? skipToken);

    const isImportedToken = Boolean(
        tokenBalance &&
            savedTokens &&
            (savedTokensFetching ||
                savedTokens[tokenBalance.platform].includes(tokenBalance.tokenContract ?? ""))
    );

    const canClaim = tokenBalance?.claimInfo != null && !tokenBalance?.isClaimed;

    const deleteSavedToken = async () => {
        if (tokenBalance && isImportedToken) {
            const res = await deleteImportedToken({
                token: tokenBalance.tokenContract ?? "",
                chain: tokenBalance.platform,
            }).unwrap();
            if (res.success) {
                triggerHaptic();
                navigate("/home");
            }
        }
    };

    const claimTokens = useCallback(async () => {
        try {
            if ((accountBalance?.chains.TON.nativeToken.balance ?? 0) < 0.1) {
                sendNotification(t("claim-token-alert.not-enough"), "error");
                return;
            }

            if (!airDropStarted(tokenBalance!.claimInfo!)) {
                sendNotification(t("claim-token-alert.airdrop-not-started"), "error");
                return;
            }

            setProcessing(true);
            const title = t("send.confirm-transaction");

            if (multichainAccount && account) {
                const pin = await confirm({ title });

                const mnemonics = cryptographyController.HashToKey(
                    pin,
                    account.masterIV,
                    account.masterHash
                );

                if (!mnemonics) {
                    sendNotification(t("common.wrong-password"), "error");
                    setProcessing(false);
                    return;
                }

                await multichainAccount.claimToken(tokenBalance!.claimInfo!, mnemonics);

                sendNotification(t("claim-token-alert.success-claim"), "success");
                setIsClaimed(true);

                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }
        } catch (error) {
            Sentry.captureException(error);
            sendNotification(t("claim-token-alert.error-claim"), "error");
        } finally {
            setProcessing(false);
        }
    }, [multichainAccount, account, tokenBalance]);

    return (
        <BaseLayout>
            <div className={s.detail}>
                {!accountBalanceLoading && tokenBalance ? (
                    <TokenDetailInfo token={tokenBalance} />
                ) : null}
                {!accountBalanceLoading && tokenBalance && isImportedToken ? (
                    <BigButton
                        title="Убрать из списка"
                        onClick={deleteSavedToken}
                        style={{ color: "rgb(var(--accent-danger))" }}
                    />
                ) : null}
                {!accountBalanceLoading && canClaim && !isClaimed && (
                    <AlertMessage
                        iconId="claim-token"
                        description={t("claim-token-alert.description")}
                        btnText={t("claim-token-alert.btn")}
                        onClick={claimTokens}
                        isLoading={isProcessing}
                    />
                )}
                {!priceHistoryFetching ? (
                    <Chart
                        currentPrice={tokenBalance?.price ?? 0}
                        priceChange={tokenBalance?.change24h ?? 0}
                        points={priceHistory}
                    />
                ) : (
                    <SkeletonRect borderRadius={32} width="100%" height={298} />
                )}
                {!accountBalanceLoading && tokenBalance ? (
                    <TransactionsHistoryListDetail token={tokenBalance} />
                ) : null}
            </div>
        </BaseLayout>
    );
};
