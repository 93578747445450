import clsx, { ClassValue } from "clsx";
import React from "react";

import { Radio } from "@/shared/components";

import { SvgSelector } from "@/shared/lib/assets/svg-selector";
import { useHaptic } from "@/shared/lib/hooks/useHaptic";

import styles from "./Section.module.scss";

interface SettingsRadioProps {
    children: React.ReactNode;
    checked?: boolean;
    onSelect?: () => void;
    className?: ClassValue;
    disabled?: boolean;
}

export const SectionRadio = ({
    children,
    checked,
    onSelect,
    className,
    disabled,
}: SettingsRadioProps) => {
    const triggerHaptic = useHaptic();

    return (
        <Radio
            onClick={() => {
                onSelect?.();
                triggerHaptic();
            }}
            className={clsx(
                styles.item,
                {
                    [styles["item--disabled"]]: disabled,
                },
                className
            )}
            disabled={disabled}
        >
            {children}

            {checked && (
                <span className={styles.item__icon}>
                    <SvgSelector id="checked" />
                </span>
            )}
        </Radio>
    );
};
