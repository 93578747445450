import { initUtils } from "@tma.js/sdk";
import clsx from "clsx";
import { FC } from "react";

import { IAppsResult } from "@/shared/api/apps";

import { SvgSelector } from "@/shared/lib/assets/svg-selector";

import { useHaptic } from "@/shared/lib/hooks/useHaptic";

import s from "./AppItem.module.sass";

interface AppItemProps extends IAppsResult {
    isLastInSlider?: boolean;
    isFull?: boolean;
    isDesktop?: boolean;
    title: string;
    description: string;
    app_link: string;
    preview_img: string;
}

export const AppItem: FC<AppItemProps> = ({
    preview_img,
    app_link,
    title,
    description,
    isLastInSlider,
    isFull,
    isDesktop,
}) => {
    const utils = initUtils();

    const triggerHaptic = useHaptic();

    const handleOpenLink = () => {
        triggerHaptic();
        if (app_link.startsWith("https://t.me")) {
            utils.openTelegramLink(app_link);
        } else {
            utils.openLink(app_link);
        }
    };

    return (
        <div
            className={clsx(
                s.item,
                { [s.itemLast]: isLastInSlider },
                { [s.isFull]: isFull },
                { [s.isDesktop]: isDesktop }
            )}
            onClick={handleOpenLink}
        >
            <div className={s.left}>
                <img src={preview_img} alt={title} className={s.logo} />
                <div className={s.info}>
                    <div className={s.title}>{title}</div>
                    <div className={s.description}>{description}</div>
                </div>
            </div>
            <SvgSelector id="chevron-right-gray" />
        </div>
    );
};
