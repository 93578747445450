import { initUtils } from "@tma.js/sdk";
import { chunk } from "lodash";
import { FC, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { v1 } from "uuid";

import "swiper/swiper-bundle.css";
import { AppItem, AppItemSkeleton } from "@/entities/app";

import { ImageSwapper } from "@/widgets/apps/ui/AppsList/ImageSwapper";
import { App, IAppsResult } from "@/shared/api/apps";

import { useHaptic } from "@/shared/lib/hooks/useHaptic";
import s from "./AppsList.module.sass";

interface AppsListProps {
    apps: IAppsResult[];
    banners: App[];
    isLoading: boolean;
}

export const AppsList: FC<AppsListProps> = ({ apps, banners, isLoading }) => {
    const { t } = useTranslation();
    const utils = initUtils();
    const navigate = useNavigate();

    const triggerHaptic = useHaptic();

    const groupedItems = useMemo(() => {
        return apps.reduce((acc, item) => {
            item.categories?.forEach((category) => {
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push(item);
            });
            return acc;
        }, {} as Record<string, IAppsResult[]>);
    }, [apps]);

    const handleNavigate = useCallback(
        (href: string, type: string) => {
            triggerHaptic();
            if (type === "TG") {
                utils.openTelegramLink(href);
            } else if (type === "INTERNAL") {
                navigate(href);
            } else {
                utils.openLink(href);
            }
        },
        [navigate, utils]
    );

    const renderSection = useCallback(
        (category: string, items: IAppsResult[], isAll = false) => {
            const chunkedItems = chunk(items, 3);
            const isAppFull = items.length <= 3;

            return (
                <div key={category}>
                    <div className={s.titleContainer}>
                        <div className={s.title}>{category}</div>
                        <Link
                            to={`/apps/${category}`}
                            className={s.button}
                            onClick={() => triggerHaptic()}
                        >
                            {t("apps.view-all")}
                        </Link>
                    </div>
                    <div className={s.scrollContainer}>
                        {chunkedItems.map((chunk, index) => (
                            <div className={s.wrapperContainer} key={index}>
                                {isLoading
                                    ? Array(3)
                                          .fill(null)
                                          .map((_, index) => <AppItemSkeleton key={index} />)
                                    : chunk.map((el) => (
                                          <AppItem
                                              {...el}
                                              isFull={isAppFull}
                                              key={v1()}
                                              isLastInSlider={
                                                  (!isAll && index === 2) || chunk.length === 1
                                              }
                                          />
                                      ))}
                            </div>
                        ))}
                    </div>
                </div>
            );
        },
        [isLoading, t]
    );

    return (
        <>
            <Swiper
                allowTouchMove={true}
                mousewheel={{ forceToAxis: true }}
                autoplay={{ delay: 4000 }}
                spaceBetween={12}
                slidesPerView={1.05}
                freeMode={true}
                observer={true}
                observeParents={true}
                touchReleaseOnEdges={true}
                className={s.appListMain}
            >
                {banners.map((banner, index) => (
                    <SwiperSlide key={index}>
                        <div onClick={() => handleNavigate(banner.link.href, banner.link.type)}>
                            <ImageSwapper imageUrl={banner.background}>
                                {banner?.preview && (
                                    <div className={s.bannerLine}>
                                        <img className={s.bannerPreview} src={banner.preview} />
                                        <div className={s.nameContainer}>
                                            {banner?.title && (
                                                <div>
                                                    <strong
                                                        style={
                                                            banner.titleColor
                                                                ? { color: banner.titleColor }
                                                                : {}
                                                        }
                                                    >
                                                        {banner.title}
                                                    </strong>
                                                </div>
                                            )}
                                            {banner?.description && (
                                                <div>
                                                    <span
                                                        style={
                                                            banner.descriptionColor
                                                                ? { color: banner.descriptionColor }
                                                                : {}
                                                        }
                                                    >
                                                        {banner?.description}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </ImageSwapper>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            {groupedItems.DeLab && renderSection("DeLab", groupedItems.DeLab)}

            {Object.keys(groupedItems)
                .filter((category) => category !== "DeLab")
                .map((category) => renderSection(category, groupedItems[category]))}

            {apps && renderSection("all-apps", apps, true)}
        </>
    );
};
