import React, { ReactNode, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";

import s from "./AppsList.module.sass";

interface ImageSwapperProps {
    imageUrl: string;
    children: ReactNode;
}

export const ImageSwapper: React.FC<ImageSwapperProps> = ({ imageUrl, children }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => setLoaded(true);
    }, [imageUrl]);

    return (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
            {!loaded && (
                <ContentLoader
                    speed={2}
                    width="calc(100vw - 42px)"
                    height="250px"
                    style={{ borderRadius: "24px", maxWidth: "435px" }}
                    backgroundColor={"rgba(49, 54, 62, 1)"}
                    foregroundColor={"rgb(var(--tertiary-bg))"}
                >
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                </ContentLoader>
            )}
            {loaded && (
                <div
                    className={`${s.bannerContainer} ${loaded ? s.loaded : ""}`}
                    style={{ backgroundImage: `url('${imageUrl}')`, maxWidth: "435px" }}
                >
                    {children}
                </div>
            )}
        </div>
    );
};
