import { initUtils } from "@tma.js/sdk";
import { FC } from "react";
import ContentLoader from "react-content-loader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetBannersQuery } from "@/shared/api/apps";
import { SvgSelector } from "@/shared/lib/assets/svg-selector";
import { useHaptic } from "@/shared/lib/hooks/useHaptic";
import { CHAINS } from "@/shared/lib/types";
import s from "./BannerList.module.scss";

interface Link {
    type: "TG" | "INTERNAL" | "EXTERNAL";
    href: string;
}

export interface App {
    title: string;
    description: string;
    preview: string;
    background: string;
    link: Link;
}

interface BannerListProps {
    disabled?: boolean;
}

export const BannerList: FC<BannerListProps> = ({ disabled }) => {
    const {
        i18n: { language },
        t,
    } = useTranslation();
    const { data } = useGetBannersQuery(language);
    const utils = initUtils();
    const navigate = useNavigate();

    const triggerHaptic = useHaptic();

    // TODO прямая ссыль на coffee.swap
    const navigateBanner = (banner: any) => {
        triggerHaptic();
        if (banner.link.type === "TG") {
            utils.openTelegramLink(banner.link.href);
        } else if (banner.link.type === "INTERNAL") {
            if (banner.link.href === "/swap")
                navigate(
                    banner.link.href === "/swap" ? `/swap?network=${CHAINS.TON}` : banner.link.href
                );
        } else {
            utils.openLink(banner.link.href);
        }
    };

    return (
        <div className={`${disabled ? s["banner--disabled"] : ""} ${s["banner-list-container"]}`}>
            <Swiper
                pagination={{ clickable: true }} // Enable pagination dots
                modules={[Pagination]}
                spaceBetween={12}
                slidesPerView={1.05}
                className={s.bannerList}
            >
                {(data?.banners || []).map((banner) => (
                    <SwiperSlide key={banner.title}>
                        {disabled ? (
                            <ContentLoader
                                speed={2}
                                width="100%"
                                height="72px"
                                style={{ borderRadius: "20px" }}
                                backgroundColor={"rgba(49, 54, 62, 1)"}
                                foregroundColor={"rgb(var(--tertiary-bg))"}
                            >
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                            </ContentLoader>
                        ) : (
                            <div
                                onClick={() => navigateBanner(banner)}
                                className={s.bannerContainer}
                            >
                                <img
                                    className={s.bannerPreview}
                                    src={banner.preview}
                                    alt={banner.title}
                                />
                                <span>{banner.title}</span>
                                <div className={s.bannerArrow}>
                                    <SvgSelector id={"arrow-right-2"} />
                                </div>
                            </div>
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
