import { ConnectWalletListSteps } from "../types/ConnectWalletListSteps";

export const btnText = {
    [ConnectWalletListSteps.connect_introduction]: "connect-wallet-list.add-new-connection",
    [ConnectWalletListSteps.connect_list]: "connect-wallet-list.add-new-connection",
    [ConnectWalletListSteps.connect_list_detail]: "connect-wallet-list.delete-connection",
};

export const title = {
    [ConnectWalletListSteps.connect_introduction]: "connect-wallet-list.wallet-connect",
    [ConnectWalletListSteps.connect_list]: "connect-wallet-list.wallet-connect",
    [ConnectWalletListSteps.connect_list_detail]: "connect-wallet-list.connection",
};
