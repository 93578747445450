import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { registerService } from "@/shared/lib/redux";
import { Asset, BuildBOCDTO, GetRouteDTO, Route, Transaction, coffeeSwap } from "./coffeeSwap";

export const coffeeSwapAPI = createApi({
    reducerPath: "coffeeSwapAPI",
    baseQuery: fakeBaseQuery<{
        message: string;
    }>(),
    tagTypes: ["Assets"],
    endpoints: (builder) => ({
        getAssetList: builder.query<Asset[] | null, void>({
            queryFn: async () => {
                const data = await coffeeSwap.getAssetsList();
                return {
                    data: data.data,
                };
            },
        }),
        getRoute: builder.query<Route | null, GetRouteDTO>({
            queryFn: async (params) => {
                const data = await coffeeSwap.getRoute(params);
                return {
                    data: data.data,
                };
            },
        }),
        buildBOC: builder.query<Transaction | null, BuildBOCDTO>({
            queryFn: async (params) => {
                const data = await coffeeSwap.buildBOC(params);
                return {
                    data: data.data,
                };
            },
        }),
    }),
});

registerService(coffeeSwapAPI);
export const {
    useGetAssetListQuery,
    useLazyGetAssetListQuery,
    useGetRouteQuery,
    useLazyGetRouteQuery,
} = coffeeSwapAPI;
