import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { v1 } from "uuid";

import { AppItem, AppItemSkeleton } from "@/entities/app";

import { IAppsResult } from "@/shared/api/apps";

import s from "./AppsList.module.sass";

interface AppsListProps {
    data: IAppsResult[];
    isLoading: boolean;
}

export const AppsListSingle: FC<AppsListProps> = ({ data, isLoading }) => {
    const params = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        const mainWrapper = document.getElementById("mainWrapper");
        if (mainWrapper) {
            mainWrapper.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [params.id]);

    return (
        <div>
            <h3 className={s.titleSingle}>
                {params.id !== "all-apps" ? params.id : t("common.all-apps")}
            </h3>
            <div className={s.wrapperSingle}>
                {isLoading
                    ? Array(6)
                          .fill(null)
                          .map((_, index) => <AppItemSkeleton key={index} />)
                    : data.map((el) => (
                          <AppItem {...el} key={v1()} isLastInSlider={data.length === 1} />
                      ))}
            </div>
        </div>
    );
};
