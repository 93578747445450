import clsx from "clsx";
import { FC } from "react";

import { SvgSelector } from "@/shared/lib/assets/svg-selector";

import { Button } from "../Button/Button";
import { Spinner } from "../Spinner/Spinner";

import s from "./AlertMessage.module.sass";

interface AlertMessageProps {
    description: string;
    btnText: string;
    iconId: string;
    onClick: () => void;
    isLoading?: boolean;
}

export const AlertMessage: FC<AlertMessageProps> = ({
    description,
    btnText,
    onClick,
    iconId,
    isLoading = false,
}) => {
    return (
        <div className={s.inner}>
            <div className={s.innerTop}>
                <div className={clsx(s.icon)}>
                    <SvgSelector id={iconId} />
                </div>
                <p className={s.text}>{description}</p>
            </div>

            <Button type="primary" className={s.btn} onClick={onClick} isDisabled={isLoading}>
                {btnText}
                {isLoading && <Spinner size="small" />}
            </Button>
        </div>
    );
};
