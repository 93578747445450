import { ImpactHapticFeedbackStyle, useHapticFeedback } from "@tma.js/sdk-react";
import { useCallback } from "react";

export const useHaptic = (impact: ImpactHapticFeedbackStyle = "medium") => {
    const hapticApi = useHapticFeedback();

    return useCallback(() => {
        if (hapticApi) {
            hapticApi.impactOccurred(impact);
        } else {
            console.warn("Haptic feedback API is not available");
        }
    }, [hapticApi, impact]);
};
