export const USDT_ERC20_CONTRACT_MAINNET = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
export const USDT_ERC20_CONTRACT_SEPOLIA = "0x7169D38820dfd117C3FA1f22a697dBA58d90BA06";
export const USDT_ERC20_CONTRACT_CURRENT_NETWORK =
    import.meta.env.VITE_NETWORK === "MAINNET"
        ? USDT_ERC20_CONTRACT_MAINNET
        : USDT_ERC20_CONTRACT_SEPOLIA;

export const USDT_TRC20_CONTRACT_MAINNET = "";
export const USDT_TRC20_CONTRACT_NILE = "TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj";
export const USDT_TRC20_CONTRACT_CURRENT_NETWORK =
    import.meta.env.VITE_NETWORK === "MAINNET"
        ? USDT_TRC20_CONTRACT_MAINNET
        : USDT_TRC20_CONTRACT_NILE;
