import { FC } from "react";

import { SkeletonRound, SkeletonSquare } from "@/shared/components/Skeletons";
import { SvgSelector } from "@/shared/lib/assets/svg-selector";

import s from "./AppItem.module.sass";

interface AppItemSkeleton {}

export const AppItemSkeleton: FC<AppItemSkeleton> = () => {
    return (
        <div className={s.item}>
            <div className={s.left}>
                <SkeletonSquare />
                <div className={s.info}>
                    <div className={s.title}>
                        <SkeletonRound height={17} />
                    </div>
                    <div className={s.description}>
                        <SkeletonRound height={20} widthFull />
                    </div>
                </div>
            </div>
            <SvgSelector id="chevron-right-gray" />
        </div>
    );
};
