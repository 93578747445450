import { useSetupBackButton } from "@/shared/lib";
import s from "./PrivacyPage.module.scss";

export const PrivacyPage = () => {
    useSetupBackButton();

    return (
        <div className={s.inner}>
            <h1 className={s.title}>Terms of use</h1>
            <p className={s.text}>Last updated December 26, 2023</p>

            <h1 className={s.title}>1. GENERAL</h1>

            <p className={s.text}>
                1. By using our Website and Application (DeLab or DeWallet), you confirm that you
                have read and understood these Terms and give your express consent to be bound by
                tLe terms and conditions stated herein. IF YOU DO NOT AGREE WITH THESE TERMS, YOU
                MUST IMMEDIATELY CEASE USING THE WEBSITE AND/OR THE APPLICATION.
            </p>

            <p className={s.text}>
                2. We make our Terms available in our Application in its most recent version. We
                reserve the right, in our sole discretion, to make changes or modifications to these
                Terms from time to time. We will alert you about any changes by updating the "Last
                updated" date of these Terms, and you waive any right to receive specific notice of
                each such change. It is your responsibility to periodically review these Terms to
                stay informed of updates. You will be subject to, and will be deemed to have been
                made aware of and to have accepted, the changes in any revised Terms by your
                continued use of the Application after the date such revised Terms are posted.
            </p>

            <h2 className={s.title}>2. APPLICATION</h2>

            <p className={s.text}>
                1. We provide you with the Application DeWallet that is a self-hosted, non-custodial
                wallet designed to make transactions within The Open Network blockchain. The
                Application is a software that provides the following functionality:
            </p>

            <p className={s.text}>
                1. generates public wallet addresses and encrypted private keys that you may use to
                send and receive digital assets within The Open Network blockchain;
            </p>

            <p className={s.text}>
                2. facilitates the submission of digital assets transfer instructions to The Open
                Network blockchain;
            </p>

            <p className={s.text}>
                3. allows end users to interact with third-party services providers to purchase
                digital assets. It is important to note that we do not provide digital assets sale
                and purchase services by ourselves; and/or
            </p>

            <p className={s.text}>
                4. allows users to interact with decentralized exchanges (e.g., Uniswap) to exchange
                digital assets. It is important to note that we do not provide digital assets
                exchange services by ourselves.
            </p>

            <p className={s.text}>
                2. The Application does not store your private keys, backup phrases, or passwords on
                its servers. It is important to note that you keep your private keys, backup
                phrases, or passwords secure. We recommend you write down your backup phrase and
                store it offline in such place that is not available to anybody except you. If you
                lose your private keys, backup phrases or passwords, it will not be possible for us
                to recover them for you and you will lose access to your digital assets stored by
                means of The Open Network blockchain.
            </p>

            <p className={s.text}>
                3. The Open Network blockchain is the only authentic means that records and stores
                the transactions you make by means of the Application. In order to be completed, all
                proposed transactions must be confirmed and recorded in The Open Network blockchain.
            </p>

            <p className={s.text}>
                4. The Open Network blockchain is a decentralized, peer-to-peer network supported by
                independent third parties. We do not own, control, or operate The Open Network
                blockchain. Therefore, we cannot guarantee that all of digital assets transactions
                you make by means of The Open Network Blockchain, including but not limited to any
                payments using NFT technology or payment cards,will be completed in due course.
            </p>

            <p className={s.text}>
                5. You have to inquire about the fees (e.g., validation or mining fees) associated
                with your digital assets transactions on your own. We shall not be responsible for
                any losses incurred due to the amount of transaction fees (gas) you spend (even if
                expected and/or former prices are lower than actual price).
            </p>

            <p className={s.text}>
                6. Based on our agreements with third-party services providers, the latter may:
            </p>

            <p className={s.text}>
                1. handle the payments you make to purchase digital assets by your bank card and/or
                your account set up with some payment services providers and/or provide other
                services to you;
            </p>

            <p className={s.text}>2. facilitate the exchange of digital assets;</p>

            <p className={s.text}>
                3. make it possible for you to make settlements by means of digital assets; and/or
            </p>

            <p className={s.text}>4. render other services to you.</p>

            <p className={s.text}>
                By being involved in any of the activities outlined in this Clause 2.6 above, you
                may be bound by the terms of service applicable to persons dealing with such
                third-party providers (including terms of service, privacy and cookie policies
                etc.). We shall not be held liable for any damages or losses, whether actual or
                alleged, resulting from or in connection with your use of or reliance on any such
                third-party provider.
            </p>

            <p className={s.text}>
                You may be subject to the AML/KYC regulations. For the avoidance of doubt, we take
                no responsibility whatsoever for your compliance or the compliance of any
                third-party providers with the AML/KYC regulations.
            </p>

            <h3 className={s.title}>3. REGISTRATION AND USER ACCOUNT</h3>

            <p className={s.text}>
                1. To use the Application DeWallet, it will be necessary for you to register an
                account. We do not collect, store and/or process any personal information for those
                purposes other than theinformation you create through the Application, such as (i)
                public wallet addresses; (ii) the IP address of the device you use to access the
                Application; (iii) the type of browser software you are using; (iv) the operating
                system you are using; (v) the date and time you access or use the Application; and
                (vi) other non-personally identifiable information.
            </p>

            <p className={s.text}>
                2. The collected cookies are anonymized. Their purpose is to collect information
                about use of the Website and Application in order to improve the functions of the
                Website and of the Application.
            </p>

            <p className={s.text}>
                3. You expressly consent to processing of the data mentioned in 3.1 and 3.2.
            </p>

            <p className={s.text}>
                4. During your first use of the Application, it will generate a public key and a
                secret recovery phrase for you. You may share your public key with anybody you may
                wish.
            </p>

            <p className={s.text}>
                5. You must handle your secret recovery phrase being the sequence of twenty-four
                words in accordance with Clauses 2.2 and 4.7. You acknowledge that certain methods
                of securing your secret recovery phrase, such as storing it as a digital file on
                your personal device or on a cloud storage provider, may increase the risk that your
                account or secret recovery phrase will be compromised. You further acknowledge that
                you will not share with us nor any other third party any password or secret recovery
                phrase that relates to your use of the Website and/or Application, and that we will
                not be held responsible if you do share any such password or phrase, whether you do
                so knowingly or unknowingly. For the avoidance of doubt, we take no responsibility
                whatsoever for any theft of a secret recovery phrase that involved intrusion into a
                cloud provider’s data repository.
            </p>

            <p className={s.text}>
                6. By starting the use of our Application, you represent and warrant that on each
                date of the period during which you are bound by these Terms:
            </p>

            <p className={s.text}>1. you have the legal capacity to enter into these Terms;</p>

            <p className={s.text}>2. you are not under the age of 13;</p>

            <p className={s.text}>
                3. you are not a minor in the jurisdiction in which you reside, or if a minor, you
                have received parental permission to use the Application (if it is allowed according
                to the laws of your jurisdiction);
            </p>

            <p className={s.text}>
                4. you are not accessing the Application through automated or non-human means,
                whether through a bot, script or otherwise;
            </p>

            <p className={s.text}>
                5. you do not use the Application for any illegal or unauthorized purpose; and
            </p>

            <p className={s.text}>
                6. your use of the Application does not violate any applicable law or regulation.
            </p>

            <p className={s.text}>
                5. You also represent that your account is personal to you and agree not to provide
                any other person or entity with your user credentials, which make it possible for
                any third party to access to your account. You shall promptly inform us of any
                unauthorized use of your login information or any other security breach. You assume
                full liability for your own and third-party activities occurring under your account.
            </p>

            <p className={s.text}>
                6. In the event that you create an account on behalf of an entity, these Terms shall
                apply to both you and the respective entity.
            </p>

            <h4 className={s.title}>4. ACCESS AND USE OF THE APPLICATION</h4>

            <p className={s.text}>
                1. You acknowledge that all the rights in and to the Application and to the Website
                (DeLab or DeWallet) and any content displayed thereon (including but not limited to
                all information, software, databases, video, audio, interface, graphics, trademarks,
                logos, designs, text, compilations and all other elements of the Website, the System
                or the Services) (the "IP") are owned by us or licensed to us and are protected by
                intellectual property laws and other laws protecting proprietary rights.
            </p>

            <p className={s.text}>
                2. Except as expressly stated herein, these Terms do not grant you any ownership
                right to, or in, our IP in respect of the Website and/or the Application.
            </p>

            <p className={s.text}>
                3. Subject to your compliance with the Terms, we grant you a non-assignable,
                non-transferable, non-sublicensable, revocable, and non-exclusive license to use the
                Application on devices you own or control.
            </p>

            <p className={s.text}>4. By accessing the Application, you agree not to:</p>

            <p className={s.text}>
                1. sub-license, sell, rent, lease, transfer, assign, reproduce, distribute, or
                otherwise commercially exploit the Application;
            </p>

            <p className={s.text}>
                2. modify, translate, adapt, merge, make derivative works of, disassemble,
                decompile, reverse compile or reverse engineer any part of the Application;
            </p>

            <p className={s.text}>
                3. access the Application in order to build a similar or competitive software;
            </p>

            <p className={s.text}>
                4. copy, reproduce, distribute, republish, download, display, post or transmit the
                Application except as expressly permitted herein; and/or
            </p>

            <p className={s.text}>
                5. remove or destroy any copyright notices or other proprietary markings contained
                in the Application.
            </p>

            <div className={s.text}>
                5. <p className={s.bold}>Your Use.</p> You will ensure that your use of the
                Application does not violate any applicable law and/or any rights any third party
                may have (including intellectual property rights, rights to personal data etc.). You
                are solely responsible for your use of the Application.
            </div>

            <div className={s.text}>
                6. <p className={s.bold}>Acceptable Use.</p> By accessing and using the Application
                you agree to abstain from using our Website and/or the Application to
            </div>

            <p className={s.text}>
                1. retrieve data or other content from the Website and/or the Application to create
                or compile, directly or indirectly, a collection, compilation, database, or
                directory without written permission from us;
            </p>

            <p className={s.text}>
                2. trick, defraud, or mislead us and other users, especially in any attempt to learn
                sensitive account information such as user passwords and/or secret recovery phrases;
            </p>

            <p className={s.text}>
                3. circumvent, disable, or otherwise interfere with security-related features of our
                Website and/or our Application, including features that prevent or restrict the use
                or copying of any Application or enforce limitations on the use of the Website
                and/or the Application contained therein;
            </p>

            <p className={s.text}>
                4. delete the copyright or other proprietary rights notice from our Website and/or
                our Application;
            </p>

            <p className={s.text}>
                5. use the Application as part of any effort to compete with us.
            </p>

            <div className={s.text}>
                7. <p className={s.bold}>Your Security.</p> Because the Application is locally
                installed, you are responsible for the security of the device on which it is
                installed, including ensuring that you promptly update and/or upgrade the
                Application and your anti-virus software and otherwise protect the device on which
                the Application is installed against malware. We are not responsible for any loss or
                damages resulting from your failure:
            </div>

            <p className={s.text}>
                1. to keep the device on which the Application is installed safe and free of any
                malware, and
            </p>

            <p className={s.text}>2. to comply with the Clauses 2.2 and 3.5.</p>

            <div className={s.text}>
                8. <p className={s.bold}>Third Party Services and Content.</p>
                When using the Application, you may view content or services provided by third
                parties, including links to web pages and services of such parties (the "
                <p className={s.bold}>Third-Party Content</p>"). We do not control, endorse or adopt
                any Third-Party Content and have no responsibility for Third Party Content,
                including, without limitation, material that may be misleading, incomplete,
                erroneous, offensive, indecent or otherwise objectionable in your jurisdiction. We
                also cannot be held liable in the events any Third-Party Content includes any
                malware and/or is capable of inflicting damage on your and/or third-party hardware
                and/or software and/or any other property you and/or a third party may own. In
                addition, your dealings or correspondence with such third parties are solely between
                you and the third party. We are not responsible or liable for any loss or damage of
                any sort incurred as a result of any such dealings and your use of Third Party
                Content is at your own risk.
            </div>

            <div className={s.text}>
                9. <p className={s.bold}>Your Funds.</p> We do not have custody over any of your
                funds stored with The Open Network blockchain, nor have we access to your private
                key and/or secret recovery phrase.
            </div>

            <div className={s.text}>
                10. <p className={s.bold}>User Content.</p> You own your user content. By posting,
                displaying, sharing or distributing user content on or through the Application, you
                grant us license to use the user content solely for the purpose of operating the
                Application.
            </div>

            <div className={s.text}>
                11. <p className={s.bold}>Trademarks.</p> All graphics, logos, service marks and
                trade names used on or in connection with the Application are our trademarks and may
                not be used without permission in connection with any third-party products or
                services.
            </div>

            <div className={s.text}>
                12. <p className={s.bold}>Fees and Taxes.</p> The Application is offered for free.
                We may impose or change a fee for the Application at any time. When applicable, we
                may give you advance notice of a fee to be imposed or such changes. If you don't
                agree with such fees and/or changes, you shall not use the Application. You are
                responsible for all taxes and fees associated with your activities. You must
                collect, report, and/or pay the correct amounts to the appropriate authorities, if
                applicable. Tax laws differ from jurisdiction to jurisdiction and may be subject to
                different interpretations by different authorities. We recommend you consult an
                appropriate tax professional for your specific tax situation.
            </div>

            <div className={s.text}>
                13. <p className={s.bold}>Risk Warning.</p>
                Trading and investing in cryptocurrency involve substantial risk of loss. Please
                make sure that you are trading and investing mindfully and understand the nature,
                complexity and risks inherent in cryptocurrency trading. You should not purchase any
                digital assets unless you understand the extent of your exposure to potential loss.
                Please make sure you are not risking funds you cannot afford to lose. In no event
                shall we be liable for any loss or damage of any kind incurred as a result of the
                use of the Application.
            </div>

            <h5 className={s.title}>5. DISCONTINUANCE OF SERVICES</h5>

            <p className={s.text}>
                1. We may, in our sole discretion and without cost to you, with or without prior
                notice, and at any time, modify or discontinue, temporarily or permanently, any
                portion of our services. You are solely responsible for storing outside of the
                DeWallet a backup of any wallet address and private key pair that you maintain in
                your wallet. Maintaining an external backup of any wallet address and private key
                pairs associated with your wallet will allow you to access the The Open Network
                blockchain upon which your wallet is secured. Such a backup will allow you to fully
                restore your wallet at any time. If you do not maintain a backup of your wallet data
                outside of the Application, you may fail to be able to access digital assets
                associated with your wallet. We shall not be held responsible or liable for any loss
                of your digital assets in the event that we discontinue or depreciate our services.
            </p>

            <h5 className={s.title}>6. SUSPENSION OF SERVICES</h5>

            <p className={s.text}>
                1. We may suspend your right to use the Website and/or the Application (DeLab or
                DeWallet) immediately if we determine that your use of the Application violated
                these Terms, including, but not limited to your license and acceptable use
                obligations.
            </p>

            <h5 className={s.title}>7. TERM AND TERMINATION</h5>

            <p className={s.text}>
                1. These Terms shall be effective since the date, when you start using the Website
                and/or Application (DeLab or DeWallet), until the date of termination in accordance
                with this Clause 7.
            </p>

            <p className={s.text}>
                2. You may terminate these Terms for any reason by ceasing use of the Website and/or
                Application.
            </p>

            <p className={s.text}>
                3. Either party (you or we) may terminate these Terms for cause if the other party
                is in material breach hereof and such material breach remains uncured for a period
                of 30 days from receipt of the other party’s notice of breach.
            </p>

            <p className={s.text}>4. We may also terminate these Terms for cause immediately:</p>

            <p className={s.text}>
                1. if we have the right to suspend your use of the Website and/or the Application in
                accordance with Clause 6;
            </p>

            <p className={s.text}>
                2. if our relationship with a third-party partner who provides software or other
                technology we use within our Application expires, terminates, or requires us to
                change the way we provide the software or other technology as part of the
                Application;
            </p>

            <p className={s.text}>3. in order to avoid undue risk of violating the law.</p>

            <p className={s.text}>5. Upon the termination of these Terms:</p>

            <p className={s.text}>1. all your rights under these Terms will terminate; and</p>

            <p className={s.text}>
                2. each party remains responsible for all fees and charges it has incurred through
                the termination and is responsible for any fees and charges it incurs during the
                post-termination period.
            </p>

            <h5 className={s.title}>8. INDEMNIFICATION</h5>

            <p className={s.text}>
                1. You agree to indemnify and hold us, our Affiliates, licensors, shareholders,
                officers, directors, agents, servants, counsel, employees, consultants, lawyers and
                other representatives harmless from any losses, costs, liabilities and expenses
                (including reasonable attorneys’ fees) relating to or arising out of:
            </p>

            <p className={s.text}>1. your use of, or inability to use the DeWallet</p>

            <p className={s.text}>2. your violation of these Terms;</p>

            <p className={s.text}>
                3. your violation of any third-party rights while using the Application;
            </p>

            <p className={s.text}>
                4. your violation of any applicable laws, rules or regulations in relation to the
                use of the Application.
            </p>

            <h5 className={s.title}>9. DISCLAIMER</h5>

            <p className={s.text}>
                1. THE WEBSITE (DELAB) AND/OR APPLICATION (DEWALLET) ARE PROVIDED "AS IS". EXCEPT TO
                THE EXTENT PROHIBITED BY LAW, OR TO THE EXTENT ANY STATUTORY RIGHTS APPLY THAT
                CANNOT BE EXCLUDED, LIMITED OR WAIVED, WE AND OUR AFFILIATES AND LICENSORS:
            </p>

            <p className={s.text}>
                1. MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED,
                STATUTORY OR OTHERWISE REGARDING THE WEBSITE AND/OR APPLICATION, THE THIRD-PARTY
                CONTENT, OR THE THIRD-PARTY SERVICES, AND
            </p>

            <p className={s.text}>
                2. DISCLAIM ALL WARRANTIES, INCLUDING ANY IMPLIED OR EXPRESS WARRANTIES (I) OF
                MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE,
                NON-INFRINGEMENT, OR QUIET ENJOYMENT, (II) ARISING OUT OF ANY COURSE OF DEALING OR
                USAGE OF TRADE, (III) THAT THE WEBSITE AND/OR APPLICATION, THIRD-PARTY CONTENT, OR
                THIRD-PARTY SERVICE WILL BE UNINTERRUPTED, ERROR FREE OR FREE OF HARMFUL COMPONENTS,
                AND (IV) THAT ANY CONTENT WILL BE SECURE OR NOT OTHERWISE LOST OR ALTERED. YOU
                ACKNOWLEDGE AND AGREE THAT YOU HAVE NOT RELIED AND ARE NOT RELYING UPON ANY
                REPRESENTATION OR WARRANTY FROM US THAT IS NOT OTHERWISE IN THESE TERMS, AND YOU
                AGREE YOU WILL NOT TAKE A POSITION IN ANY PROCEEDING THAT IS INCONSISTENT WITH THIS
                PROVISION.
            </p>

            <p className={s.text}>
                2. OUR WEBSITE AND/OR APPLICATION RELY ON TON OPEN NETWORK THAT IS AN EMERGING
                TECHNOLOGY. YOUR USE THE APPLICATION IS SUBJECT TO INCREASED RISK THROUGH YOUR
                POTENTIAL MISUSE OF THINGS SUCH AS PUBLIC/PRIVATE KEY CRYPTOGRAPHY, OR FAILING TO
                PROPERLY UPDATE OR RUN SOFTWARE TO ACCOMMODATE PROTOCOL UPGRADES. BY USING THE
                APPLICATION YOU EXPLICITLY ACKNOWLEDGE AND ACCEPT THESE HEIGHTENED RISKS. YOU
                REPRESENT THAT YOU ARE FINANCIALLY AND TECHNICALLY SOPHISTICATED ENOUGH TO
                UNDERSTAND THE INHERENT RISKS ASSOCIATED WITH USING CRYPTOGRAPHIC AND
                BLOCKCHAIN-BASED SYSTEMS AND UPGRADING YOUR SOFTWARE AND PROCESSES TO ACCOMMODATE
                APPLICATION AND PROTOCOL UPGRADES, AND THAT YOU HAVE A WORKING KNOWLEDGE OF THE
                USAGE AND INTRICACIES OF DIGITAL ASSETS. IN PARTICULAR, YOU UNDERSTAND THAT WE DO
                NOT OPERATE THE OPEN NETWORK BLOCKCHAIN OR ANY OTHER BLOCKCHAIN PROTOCOL,
                COMMUNICATE OR EXECUTE PROTOCOL UPGRADES, OR APPROVE OR PROCESS BLOCKCHAIN
                TRANSACTIONS ON BEHALF OF YOU. YOU FURTHER UNDERSTAND THAT BLOCKCHAIN PROTOCOLS
                PRESENT THEIR OWN RISKS OF USE, THAT SUPPORTING OR PARTICIPATING IN THE PROTOCOL MAY
                RESULT IN LOSSES IF YOUR PARTICIPATION VIOLATES CERTAIN PROTOCOL RULES, THAT
                BLOCKCHAIN-BASED TRANSACTIONS ARE IRREVERSIBLE, THAT YOUR PRIVATE KEY AND SECRET
                RECOVERY PHRASE MUST BE KEPT SECRET AT ALL TIMES, THAT WE WILL NOT STORE A BACKUP
                OF, NOR WILL BE ABLE TO DISCOVER OR RECOVER, YOUR PRIVATE KEY OR SECRET RECOVERY
                PHRASE, THAT DIGITALLY COPYING AND STORING YOUR SECRET RECOVERY PHRASE ON A CLOUD
                STORAGE SYSTEM OR OTHER THIRD PARTY SUPPORTED DATA STORAGE, INCLUDING YOUR PERSONAL
                DEVICE, MAY INCREASE THE RISK OF LOSS OR THEFT, AND THAT YOU ARE SOLELY RESPONSIBLE
                FOR ANY APPROVALS OR PERMISSIONS YOU PROVIDE BY CRYPTOGRAPHICALLY SIGNING BLOCKCHAIN
                MESSAGES OR TRANSACTIONS, ESPECIALLY THOSE RESPONDING TO SOLICITATIONS AND OTHER
                PROMPTS FROM THIRD PARTIES.
            </p>

            <p className={s.text}>
                3. YOU FURTHER UNDERSTAND AND ACCEPT THAT DIGITAL ASSETS PRESENT MARKET VOLATILITY
                RISK, TECHNICAL SOFTWARE RISKS, REGULATORY RISKS, AND CYBERSECURITY RISKS. YOU
                UNDERSTAND THAT THE COST AND SPEED OF A BLOCKCHAIN-BASED SYSTEM IS VARIABLE, THAT
                COST MAY INCREASE DRAMATICALLY AT ANY TIME, AND THAT COST AND SPEED IS NOT WITHIN
                OUR CAPABILITY TO CONTROL. YOU UNDERSTAND THAT PROTOCOL UPGRADES MAY INADVERTENTLY
                CONTAIN BUGS OR SECURITY VULNERABILITIES THAT MAY RESULT IN LOSS OF FUNCTIONALITY
                AND ULTIMATELY FUNDS.
            </p>

            <p className={s.text}>
                4. YOU AGREE THAT YOU ALONE, AND NOT US, ARE RESPONSIBLE FOR ANY TRANSACTIONS THAT
                YOU ENGAGE IN WITH REGARD TO SUPPORTING ANY BLOCKCHAIN PROTOCOL WHETHER THROUGH
                TRANSACTION VALIDATION OR OTHERWISE, OR ANY TRANSACTIONS THAT YOU ENGAGE IN WITH ANY
                THIRD-PARTY-DEVELOPED SMART CONTRACT OR TOKEN, INCLUDING TOKENS THAT WERE CREATED BY
                A THIRD PARTY FOR THE PURPOSE OF FRAUDULENTLY MISREPRESENTING AFFILIATION WITH ANY
                BLOCKCHAIN PROJECT. YOU AGREE THAT WE ARE NOT RESPONSIBLE FOR THE REGULATORY STATUS
                OR TREATMENT IN ANY JURISDICTION OF ANY DIGITAL ASSETS THAT YOU MAY ACCESS OR
                TRANSACT WITH USING OUR APPLICATION. YOU EXPRESSLY ASSUME FULL RESPONSIBILITY FOR
                ALL OF THE RISKS OF ACCESSING AND USING THE APPLICATION TO INTERACT WITH BLOCKCHAIN
                PROTOCOLS.
            </p>

            <p className={s.text}>10. LIMITATION OF LIABILITY</p>

            <p className={s.text}>
                1. To the fullest extent permitted by applicable law, in no event will we or any of
                our shareholders, officers, directors, agents, servants, counsel, employees,
                consultants, lawyers, and other representatives authorized to act, acting, or
                purporting to act on our behalf be liable to you under contract, tort, strict
                liability, negligence, or any other legal or equitable theory, for:
            </p>

            <p className={s.text}>
                1. any lost profits, data loss, cost of procurement of substitute goods or services,
                or direct, indirect, incidental, special, punitive, compensatory, or consequential
                damages of any kind whatsoever resulting from: (i) your use of, or conduct in
                connection with, the Application; (ii) any unauthorized use of your wallet address
                and/or private key due to your failure to maintain the confidentiality of your
                wallet; (iii) any interruption or cessation of transmission to or from the
                Application(DeWallet); or (iv) any bugs, viruses, trojan horses, or the like that
                are found in the Application(DeWallet) or that may be transmitted to or through our
                services by any third party (regardless of the source of origination), or
            </p>

            <p className={s.text}>
                2. any direct damages. These limitations apply regardless of legal theory, whether
                based on tort, strict liability, breach of contract, breach of warranty, or any
                other legal theory, and whether or not we were advised of the possibility of such
                damages. Some jurisdictions do not allow the exclusion or limitation of liability
                for consequential or incidental damages, so the above limitation may not apply to
                you. Under no circumstances shall we be required to deliver to you any digital
                assets as damages, be subject to specific performance or any other similar remedy.
            </p>

            <p className={s.text}>
                2. Hereby, you expressly waive any objection based on lack of personal jurisdiction,
                place of residence, improper venue, or forum non conveniens in any such action.
            </p>

            <p className={s.text}>11. MISCELLANEOUS</p>

            <div className={s.text}>
                1. <p className={s.bold}>Assignment.</p> You will not assign or otherwise transfer
                these Terms or any of your rights and obligations under these Terms, without our
                prior written consent. Any assignment or transfer in violation of this Clause 13.1
                will be void. We may assign these Terms without your consent:
            </div>

            <p className={s.text}>
                1. in connection with a merger, acquisition or sale of all or substantially all of
                our assets, or
            </p>

            <p className={s.text}>2. to any Affiliate or as part of a corporate reorganization;</p>

            <p className={s.text}>
                and effective upon such assignment, the assignee is deemed substituted for us as a
                party to these Terms and we are fully released from all of our obligations and
                duties to perform under these Terms. Subject to the foregoing, these Terms will be
                binding upon, and inure to the benefit of the parties and their respective permitted
                successors and assigns.
            </p>

            <div className={s.text}>
                2. <p className={s.bold}>Entire Agreement. </p> These Terms constitute the whole
                agreement between you and us in relation to the Services and supersedes and
                extinguishes any prior drafts, agreements, and undertakings, whether in writing or
                oral, relating thereto.
            </div>

            <div className={s.text}>
                3. <p className={s.bold}>Force Majeure.</p> Neither party nor their respective
                Affiliates will be liable for any delay or failure to perform any obligation under
                these Terms where the delay or failure results from any cause beyond such party’s
                reasonable control, including but not limited to acts of God, utilities or other
                telecommunications failures, cyber-attacks, earthquakes, storms or other natural
                disasters, pandemics, blockages, embargoes, riots, acts or orders of government,
                acts of terrorism, or war.
            </div>

            <div className={s.text}>
                4. <p className={s.bold}>Severability.</p> In the event that any provision of these
                Terms is unenforceable under applicable law, the validity or enforceability of the
                remaining provisions will not be affected. To the extent any provision of these
                Terms is judicially determined to be unenforceable, a court of competent
                jurisdiction may reform any such provision to make it enforceable. The provisions of
                these Terms will, where possible, be interpreted so as to sustain its legality and
                enforceability.
            </div>

            <div className={s.text}>
                5. <p className={s.bold}>Independent Contractors.</p> Nothing in these Terms is
                intended to, nor shall create any partnership, joint venture, agency, consultancy or
                trusteeship. We and you are independent contractors for purposes of these Terms.
            </div>

            <div className={s.text}>
                6. <p className={s.bold}>Export and Sanctions Compliance.</p> In connection with
                these Terms, you will comply with all applicable import, re-import, sanctions,
                anti-boycott, export, and re-export control laws and regulations, including all such
                laws and regulations that prohibit certain transactions. For clarity, you are solely
                responsible for compliance related to the manner in which you choose to use the
                Services. You may not use the Services if you are the subject to the Sanctions.
            </div>

            <div className={s.text}>
                7. <p className={s.bold}>Notice.</p> We may provide any notice to you under these
                Terms by: (i) posting a notice in the Application; or (ii) sending a message to the
                email address if it was previously provided by you. To give us notice under these
                Terms, you must contact us.
            </div>

            <p className={s.text}>12. DEFINITIONS AND INTERPRETATION</p>

            <p className={s.text}>
                1. In these Terms, the following terms shall have the following meaning:
            </p>

            <div className={s.text}>
                "<p className={s.bold}>Affiliate</p>" means, with respect to any person:
            </div>

            <p className={s.text}>
                1. any other person directly or indirectly through one or more intermediaries is
                controlled by, or controlling of, or under common control with, that person;
            </p>

            <p className={s.text}>
                2. a legal entity that shares the same investment management or investment advisory
                company with, or acts solely as bare nominee holder on behalf of, the first person,
                or a fund for which the first person acts as bare nominee;
            </p>

            <p className={s.text}>
                3. with respect to a first person that is a natural person, any person that is a
                family member of the first person and any person controlled by any of them (whether
                individually or collectively) and any trust for which the first person directly or
                indirectly, serves as settlor or trustee or in a similar capacity (including,
                without limitation, any protector or settler of a trust), and any person who is
                controlled by any such trust or estate.
            </p>

            <div className={s.text}>
                "<p className={s.bold}>IP</p> has the meaning ascribed to it in Clause 4.1."
            </div>

            <div className={s.text}>
                "<p className={s.bold}>Sanctions</p>" means any sanction and/or restrictive measure
                targeting certain government officials and other persons and entities implemented
                from time to time.
            </div>

            <div className={s.text}>
                "<p className={s.bold}>Terms</p>" has the meaning ascribed to it in Clause 1.1.
            </div>

            <div className={s.text}>
                "<p className={s.bold}>Third-party Content</p>" has the meaning ascribed to it in
                Clause 4.8.
            </div>
        </div>
    );
};
