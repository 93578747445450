import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import App from "./app/App";

const rootElement = document.getElementById("root");

if (!rootElement) {
    throw new Error("rootElement is null");
}

Sentry.init({
    dsn: "https://223f6859b957e94c81ee9802a5d505cd@sentry.swap.coffee/4",
    integrations: [Sentry.browserTracingIntegration()],
    // Tracing
    tracesSampleRate: 0.3,
});

// Statistics.init();
const root = createRoot(rootElement);
root.render(<App />);
