import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import { registerService } from "@/shared/lib/redux";

export interface IAppsResult {
    id: string;
    preview_img: string;
    title: string;
    categories: string[];
    app_link: string;
    titleColor?: string;
    descriptionColor?: string;
    description: string;
}

export interface Link {
    type: "TG" | "INTERNAL" | "LINK";
    href: string;
}

export interface Banner {
    title: string;
    preview: string;
    link: Link;
}

export interface App {
    title: string | null;
    description: string | null;
    preview: string | null;
    background: string;
    titleColor?: string;
    descriptionColor?: string;
    link: Link;
}

export interface BannersResponse {
    banners: Banner[];
    apps: App[];
}

export interface IBannerLine {
    title: string;
    preview: string;
    link: {
        type: string;
        href: string;
    };
}

export const appsApi = createApi({
    reducerPath: "appsApi",
    baseQuery: fakeBaseQuery(),
    endpoints: (builder) => ({
        getApps: builder.query<IAppsResult[], string>({
            queryFn: async (lang) => {
                try {
                    const response = await axios.get<{ data: IAppsResult[] }>(
                        `https://raw.githubusercontent.com/delab-team/apps-data/main/${
                            lang === "ru" ? "data-ru.json" : "data-en.json"
                        }`
                    );
                    return { data: response.data.data };
                } catch (error) {
                    return {
                        error: {
                            status: "FETCH_ERROR",
                            error: String(error),
                        },
                    };
                }
            },
        }),
        getBanners: builder.query<BannersResponse, string>({
            queryFn: async (lang) => {
                try {
                    const response = await axios.get<BannersResponse>(
                        `https://raw.githubusercontent.com/delab-team/apps-data/wallet-app-content/${
                            lang === "ru" ? "apps-ru.json" : "apps-en.json"
                        }`
                    );

                    console.log(response, "response response");
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            status: "FETCH_ERROR",
                            error: String(error),
                        },
                    };
                }
            },
        }),
    }),
});

registerService(appsApi);
export const { useGetAppsQuery, useGetBannersQuery } = appsApi;
