import clsx from "clsx";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
    ChangeValue,
    PlatformName,
    TokenIcon,
    PlatformNameSize,
    AmountFormat,
} from "@/shared/components/";
import { CHAINS, TokenClaimInfo } from "@/shared/lib/types";
import s from "./TokenListItem.module.sass";

interface ITokenListItemProps {
    name?: string;
    balance?: number;
    balanceUSD?: number;
    tokenPrice?: number;
    change24?: number;
    icon?: string;
    onClick?: () => void;
    chain?: CHAINS;
    noHover?: boolean;
    isClaimed?: boolean;
    claimInfo?: TokenClaimInfo;
}

export const TokenListItem: FC<ITokenListItemProps> = ({
    name,
    balance,
    balanceUSD,
    tokenPrice,
    change24,
    icon,
    onClick,
    chain,
    noHover,
    isClaimed,
    claimInfo,
}) => {
    const showChainLogo = name !== "TON" && chain;

    const { t } = useTranslation();

    const canClaim = claimInfo != null && isClaimed === false;

    return (
        <div
            className={clsx(
                s.token,
                { [s.tokenClickable]: Boolean(onClick) },
                { [s.tokenNoHover]: noHover },
                "token-" + name + "-" + chain
            )}
            onClick={onClick}
        >
            <div className={s.left}>
                <TokenIcon icon={icon} showChain chain={chain} />
                <div className={s.info}>
                    <div className={s.name}>
                        <span>{name}</span>
                        {showChainLogo && (
                            <PlatformName chain={chain} size={PlatformNameSize.SMALL12} />
                        )}
                    </div>
                    <div className={s.price}>
                        {canClaim ? (
                            <span className={s.priceAwaitClaim}>{t("common.await-claim")}</span>
                        ) : (
                            <div>
                                <span className={s.priceMain}>$</span>
                                <AmountFormat className={s.priceMain} value={tokenPrice ?? 0} />
                            </div>
                        )}
                        {!canClaim && <ChangeValue percent value={change24} />}
                        {/* <span className={s.priceChange}>+2.3%</span> */}
                    </div>
                </div>
            </div>
            <div className={s.total}>
                <AmountFormat className={s.totalCount} value={balance ?? 0} />
                <div className={s.totalAmount}>
                    $<AmountFormat className={s.totalAmount} value={balanceUSD ?? 0} />
                </div>
            </div>
        </div>
    );
};
